import { FC } from 'react'

import * as React from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { buttonClass } from './button.class'

/* Components =================================================================================== */
import {
  ComponentBaselineType,
  ComponentSizeType,
  ComponentLeftRightType,
  ComponentProps,
  ComponentContextProps,
} from '../__core/component.types'

/* Components =================================================================================== */
import { Box } from '../box/box.component'

/* Types ======================================================================================== */
export type ButtonProps = {
  ariaLabel?: string
  bind?: object
  float?: boolean
  icon?: any
  iconPosition?: 'left' | 'right'
  square?: boolean
  style?: React.CSSProperties
  type?: 'button' | 'submit'
  center?: boolean
  as?: any
  children?: any
  arrow?: 'below' | 'above' | 'left' | 'right'
  border?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
  semiBold?: boolean
  bold?: boolean
  disableAnimation?: boolean
} & ComponentProps

const defaultProps: {
  baseline?: ComponentBaselineType
  iconPosition?: ComponentLeftRightType
  size?: ComponentSizeType
} = {
  baseline: 'surface',
  iconPosition: 'left',
  size: 'lg',
}

/* <Button /> =================================================================================== */
const ButtonComponent: React.FC<ButtonProps & ComponentContextProps> = props => {
  const {
    label,
    ariaLabel = props.label,
    alt,
    baseline,
    bind,
    float,
    icon,
    iconPosition,
    onClick,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    center = true,
    size = 'md',
    square,
    style,
    type = 'button',
    className,
    as,
    children,
    arrow,
    border,
    borderContrast,
    semiBold,
    bold,
    disableAnimation,
  } = props

  const {
    base,
    proped,
    baseAnimated,
    baseThemed,
    iconBase,
    arrowBase,
    baseBold,
    baseSemiBold,
  } = buttonClass.setProps({
    alt,
    baseline,
    float,
    icon,
    iconPosition,
    label,
    size,
    square,
    arrow,
    border,
    borderContrast,
    center,
    disableAnimation,
  })

  const handleClick = (e: any) => {
    if (onClick && baseline !== 'disabled') {
      onClick(e)
    }
  }

  if (!as) {
    return (
      <button
        className={classes(
          'aeki-button-base',
          className,
          base,
          float && proped.float,
          baseAnimated,
          baseThemed,
          semiBold && baseSemiBold,
          bold && baseBold,
        )}
        style={style}
        onClick={handleClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        type={type}
        aria-label={ariaLabel}
        {...bind}
      >
        <span className={classes('aeki-button-icon-base', iconBase)}>{icon}</span>
        <span className={'aeki-button-label'}>{label}</span>
        {children}
        {props.arrow && (
          <Box baseline="none" absolute minWidth={20} minHeight={20} className={arrowBase}></Box>
        )}
      </button>
    )
  }

  return React.cloneElement(as, {
    title: ariaLabel || label,
    ...as.props,
    className: classes(
      'aeki-button-base',
      base,
      float && proped.float,
      baseAnimated,
      baseThemed,
      className,
    ),
    style,
    onClick: handleClick,
    onMouseOver: onMouseOver,
    onMouseLeave: onMouseLeave,
    onTouchEnd: onTouchEnd,
    type,
    'aria-label': ariaLabel,
    ...bind,
    children: (
      <>
        <span className={classes('aeki-button-icon-base', iconBase)}>{icon}</span>
        <span className={'aeki-button-label'}>{label}</span>
        {children}
      </>
    ),
  })
}

ButtonComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Button: FC<ButtonProps> = ButtonComponent
export const ButtonRaw: FC<ButtonProps> = ButtonComponent
