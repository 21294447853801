import { useEffect } from 'react'
import { classes, keyframes, style, stylesheet } from 'typestyle'
import { useNavigate } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useBoolean from '../../../__aeki__/hooks/use-boolean'
import useValue from '../../../__aeki__/hooks/use-value'
import useFetch from '../../../__aeki__/hooks/use-fetch'

/* Components =================================================================================== */
import { Box } from '../../../__aeki__/components/box/box.component'
import { Button } from '../../../__aeki__/components/button'
import { Dropdown } from '../../../__aeki__/components/dropdown'
import { EvenMoreSimplerPagination } from '../../../__aeki__/modules/navigation/pagination'
import { GoogleLoader } from '../../../__aeki__/components/google-map/google-loader'
import { GooglePlaceWrapper } from '../../../__aeki__/components/google-map/google-place-wrapper'
// import { HEADER_HEIGHT } from '../../../__aeki__/components/app-layout/app-layout.constants'
import { Line } from '../../../__aeki__/components/line'
// import { props.maxWidth } from '../../../routes/childcares'
import { Search } from '../../../__aeki__/components/search/search.component'
import { Text } from '../../../__aeki__/components/text/text.component'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../../../__aeki__/components/style-class/style-class.utils'
import { f17i } from '../../../__aeki__/styles/styleset/font-size/f17i'
import { h100 } from '../../../__aeki__/styles/styleset/height/h100'
import { mr8 } from '../../../__aeki__/styles/styleset/margin/mr8'
import { p4 } from '../../../__aeki__/styles/styleset/padding/p4'
import { pb8 } from '../../../__aeki__/styles/styleset/padding/pb8'
import { pt4 } from '../../../__aeki__/styles/styleset/padding/pt4'
import { w100 } from '../../../__aeki__/styles/styleset/width/w100'
import { paddingVerticalOuter } from '../../../__aeki__/styles/default/padding'
import { pb4 } from '../../../__aeki__/styles/styleset/padding/pb4'
import { mb8 } from '../../../__aeki__/styles/styleset/margin/mb8'
import { pt8 } from '../../../__aeki__/styles/styleset/padding/pt8'
import { pb12 } from '../../../__aeki__/styles/styleset/padding/pb12'
import { mb4 } from '../../../__aeki__/styles/styleset/margin/mb4'
import { mt8 } from '../../../__aeki__/styles/styleset/margin/mt8'
import { mt4 } from '../../../__aeki__/styles/styleset/margin/mt4'

export const LocationSearch = (props: {
  onLocation?: any
  componentProps?: any
  isMapOpen?: boolean
  style?: any
  maxWidth?: any
  isMobile?: any
  onToggle?: any
  className?: string
}) => {
  const history = useNavigate()
  const [focused, $focused] = useBoolean(false)
  const [isSearching, $isSearching] = useValue(-1)
  const [searchText, $searchText] = useValue('')

  const [search, $search] = useFetch({ data: [], page: 1, totalPage: 1 })

  useEffect(() => {
    if (search.mounted && searchText) {
      $search.get({
        search: {
          page: 1,
        },
        url: `${process.env.REACT_APP_API_URL}/a/childcares/search/${searchText}`,
      })
    }
  }, [search.mounted, searchText])

  useEffect(() => {
    if (focused) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = ''
    }

    if (props.onToggle) {
      props.onToggle(focused)
    }
  }, [focused])

  return (
    <>
      <Dropdown
        floatProps={{
          anchorPosition: 'bottom left',
          dialogPosition: 'top left',
          fixed: false,
        }}
        floatDialogProps={{
          className: style({
            width: props.maxWidth - 32,
            $nest: {
              ...createMediaQuery.md({
                width: 'calc(100% - 24px)',
              }),
              ...createMediaQuery.sm({
                width: 'calc(100% - 16px)',
              }),
            },
          }),
        }}
      >
        <Button
          style={props.style}
          className={classes(props.isMobile ? '' : mr8, props.className)}
          icon={
            <span
              className={classes(
                'material-icons',
                f17i,
                style({ color: props.componentProps?.searchText ? 'white' : '' }),
              )}
            >
              search
            </span>
          }
          size="md"
          alt={props.componentProps?.searchText}
          baseline={props.componentProps?.searchText ? 'secondary' : 'background'}
          label={props.isMobile ? '' : 'Search'}
          onClick={() => {
            $focused.set(true)
          }}
        />
        {() => {
          return <></>
        }}
      </Dropdown>
      {focused && (
        <Box
          baseline="none"
          style={{
            top: 53,
            left: 0,
            width: '100%',
            height: `calc(100% - ${53}px)`,
            zIndex: 20,
            background: 'rgba(0,0,0,0.8)',
          }}
          fixed
        >
          <Box row>
            <Line className={mb8} />
            <GoogleLoader
              component={GooglePlaceWrapper}
              className={classes(w100, h100)}
              loaderProps={{
                height: 44,
                width: '100%',
              }}
              componentProps={{
                ...props.componentProps,
                noWrap: true,
                maxRow: 3,
                maxWidth: props.maxWidth,
                onTextChange: (searchText: string) => {
                  $searchText.set(searchText)
                },
                additionalTab: search?.payload?.data?.length > 0 && (
                  <Box className={paddingVerticalOuter} row>
                    <Line className={mb8} />
                    <Box maxWidth={props.maxWidth} centerAutoX fullWidth row>
                      <Box className={classes(pt4, pb4)} alignCenterY flex baseline="none">
                        <Text size="sm" baseline="surface" alt uppercase shrink>
                          {searchText
                            ? `Childcare including keyword "${searchText}"`
                            : 'All Childcare'}{' '}
                          ({search.payload.page}/{search.payload.totalPage})
                        </Text>
                      </Box>
                      {search.payload.data.map((item: any, key: any) => {
                        return (
                          <Box
                            baseline="none"
                            className={classes(pb8, pt4, STYLES.containerItem)}
                            key={key}
                            alignCenterY
                            onClick={() => {
                              history(`/a/c/${item.id}`)
                            }}
                          >
                            <Box row>
                              <Text size="md" baseline="surface">
                                {item.body.generalInformation.name}
                              </Text>
                              <Text size="xs" baseline="surface">
                                {item.body.generalInformation.addressLine1}
                              </Text>
                            </Box>
                          </Box>
                        )
                      })}
                      <Box alignCenterX className={classes(p4, pb12)} baseline="none">
                        <EvenMoreSimplerPagination
                          size="xs"
                          data={search}
                          $data={$search}
                          url={`${process.env.REACT_APP_API_URL}/a/childcares/search/${searchText}`}
                        />
                      </Box>
                    </Box>
                    <Line />
                  </Box>
                ),
                children: (props: any) => {
                  return (
                    <Box maxWidth={props.maxWidth} className={paddingVerticalOuter} row>
                      <Box
                        className={classes(pt8, pb4)}
                        maxWidth={props.maxWidth}
                        fullWidth
                        centerAutoX
                      >
                        <Text baseline="surface" size="sm" shrink>
                          Type your street address or a childcare
                        </Text>
                        <Box className={classes(mb4)} baseline="background" rounded>
                          <Search
                            {...props}
                            placeholder="Ex. 960 Lillian Street or Step by Step Child Development Society"
                            size="xl"
                            style={{
                              width: '100%',
                            }}
                            border={false}
                            autoFocus={focused}
                          />
                        </Box>
                      </Box>
                      <Line className={''} />
                    </Box>
                  )
                },
                footer: (
                  <>
                    <Box
                      className={classes(paddingVerticalOuter)}
                      maxWidth={props.maxWidth}
                      centerAutoX
                      fullWidth
                      row
                    >
                      <Box className={classes(pt4, pb4, mt8)} alignCenterY flex baseline="none">
                        <Text size="sm" baseline="surface" alt shrink>
                          {'Recommended Search'}
                        </Text>
                      </Box>
                      <Box flex flexWrap>
                        <Button
                          icon={
                            isSearching === 0 ? (
                              <span
                                className={classes(
                                  'material-icons-two-tone',
                                  f17i,
                                  STYLES.iconSpin,
                                )}
                              >
                                {'hourglass_empty'}
                              </span>
                            ) : (
                              undefined
                            )
                          }
                          onClick={() => {
                            if (navigator.geolocation) {
                              $isSearching.set(0)
                              navigator.geolocation.getCurrentPosition((position: any) => {
                                history(
                                  `/a/childcares?curriculum.montessori=true&center.0=${`${position.coords.longitude}`.replace(
                                    '.',
                                    '_',
                                  )}&center.1=${`${position.coords.latitude}`.replace('.', '_')}`,
                                )
                              })
                            }
                          }}
                          label={isSearching === 0 ? 'Searching ...' : 'Montessori near me'}
                          baseline="primary"
                          size="md"
                          className={classes(mr8, mb8)}
                          square
                        />
                        <Button
                          icon={
                            isSearching === 1 ? (
                              <span
                                className={classes(
                                  'material-icons-two-tone',
                                  f17i,
                                  STYLES.iconSpin,
                                )}
                              >
                                {'hourglass_empty'}
                              </span>
                            ) : (
                              undefined
                            )
                          }
                          onClick={() => {
                            if (navigator.geolocation) {
                              $isSearching.set(1)
                              navigator.geolocation.getCurrentPosition((position: any) => {
                                history(
                                  `/a/childcares?curriculum.reggio-inspired=true&center.0=${`${position.coords.longitude}`.replace(
                                    '.',
                                    '_',
                                  )}&center.1=${`${position.coords.latitude}`.replace('.', '_')}`,
                                )
                              })
                            }
                          }}
                          label={isSearching === 1 ? 'Searching ...' : 'Reggio inspired near me'}
                          baseline="primary"
                          size="md"
                          className={classes(mr8, mb8)}
                          square
                        />
                      </Box>
                      <Line className={mt4} />
                    </Box>
                    <Box
                      className={classes(paddingVerticalOuter, pt8, pb8)}
                      maxWidth={props.maxWidth}
                      centerAutoX
                      fullWidth
                      flex
                      alignRight
                    >
                      <Button
                        label="Close"
                        baseline="background"
                        size="md"
                        square
                        onClick={$focused.reset}
                      />
                    </Box>
                  </>
                ),
              }}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

const STYLES = stylesheet({
  containerDialog: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  containerDialogContent: {
    overflow: 'hidden',
  },
  containerItem: {
    $nest: {
      '&:hover': {
        opacity: 0.6,
        cursor: 'pointer',
      },
    },
  },
  iconSpin: {
    color: 'black !important',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
    animationName: keyframes({
      '100%': {
        transform: 'rotate(360deg)',
      },
    }),
  },
})
