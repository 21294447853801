import { useEffect } from 'react'
import { classes, stylesheet } from 'typestyle'
import { CSSTransition } from 'react-transition-group'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Box } from '../box/box.component'
import { Button } from '../button'
import { Line } from '../line'
import { Text } from '../text/text.component'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../style-class/style-class.utils'
import { mr8 } from '../../styles/styleset/margin/mr8'
import { mt16 } from '../../styles/styleset/margin/mt16'
import { mt4 } from '../../styles/styleset/margin/mt4'
import { p12 } from '../../styles/styleset/padding/p12'
import { pb8 } from '../../styles/styleset/padding/pb8'
import { modalClass } from './index.class'
import useLocal from '../../hooks/use-local'
import { GLOBAL_CONTENTS } from '../../common/global-contents'

/* Types ======================================================================================== */
type Modal = {
  render?: any
  confirmRender?: any
  confirm?: () => void
  cancelRender?: any
  cancel?: () => void
  label?: string
  disableAction?: boolean
  status?: string
  props?: any
  type?: string
  componentProps?: any
  title?: string | any
  containerClassName?: string
  overlayClassName?: string
  position?: any
  fullWidth?: boolean
  fullHeight?: boolean
  closeOnOutsideClick?: boolean
}

/* ModalStack =================================================================================== */
class ModalStack {
  count = 0
  self: any = null
  storage: Modal[] = []
  components: any = {}

  setComponents = (components: any) => {
    this.components = components
  }

  add = (name: string, component: any) => {
    this.components[name] = component
  }

  push = (dialog: Modal) => {
    this.storage[this.count + 1] = dialog
    this.count++

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  pop = () => {
    delete this.storage[this.count]

    this.count--

    if (this.count < 0) {
      this.count = 0
    }

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  update = (props?: any) => {
    this.storage[this.count].props = {
      ...this.storage[this.count].props,
      ...props,
    }

    this.self.set(this)
  }

  size = () => this.count

  render = () => {
    const [self, $self] = useValue(this)
    const [local] = useLocal()
    this.self = $self

    const { containerTitle } = modalClass.setProps({})

    useEffect(() => {
      if (self.count > 0) {
        document.documentElement.style.touchAction = 'none'
        document.body.style.touchAction = 'none'
        document.body.style.overflow = 'hidden'
      } else {
        document.documentElement.style.touchAction = ''
        document.body.style.touchAction = ''
        document.body.style.overflow = ''
      }
    }, [self])

    const handleContainerClick = () => {
      self.pop()
    }

    const handleContentClick = (event: any) => {
      event.stopPropagation()
    }

    return (
      <CSSTransition in={self.count > 0} timeout={300} classNames="popin" unmountOnExit>
        <>
          {self.storage.map((dialog: Modal, key: number) => {
            const {
              componentProps,
              type = 'Prompt',
              closeOnOutsideClick = false,
              overlayClassName = '',
            } = dialog

            switch (type) {
              case 'Prompt': {
                const handdleConfirm = () => {
                  if (dialog.componentProps.confirm) {
                    dialog.componentProps.confirm()
                  } else {
                    this.pop()
                  }
                }
                return (
                  <ModalContainer
                    onClick={() => {
                      if (closeOnOutsideClick) {
                        handleContainerClick()
                      }
                    }}
                    overlayClassName={overlayClassName}
                    key={`modal-container-${key}`}
                  >
                    <Box
                      row
                      rounded
                      minWidth={320}
                      maxWidth={540}
                      fullWidth
                      onClick={handleContentClick}
                    >
                      <Box baseline="none" className={classes(p12)} row>
                        <Text size="lg" semiBold>
                          {dialog.componentProps.title}
                        </Text>
                        {dialog.componentProps.subTitle && (
                          <Text className={mt4} baseline="surface" size="xs" light>
                            {dialog.componentProps.subTitle}
                          </Text>
                        )}
                      </Box>
                      <Line />
                      {dialog.componentProps.label && (
                        <Box baseline="none" className={p12}>
                          <Text size="md">{dialog.componentProps.label}</Text>
                        </Box>
                      )}
                      {dialog.componentProps.component && (
                        <Box baseline={componentProps.componentBaseline || 'none'} className={p12}>
                          {dialog.componentProps.component}
                        </Box>
                      )}
                      <Line />
                      <Box baseline={'none'} flex alignRight className={p12}>
                        <Button
                          className={mr8}
                          label={GLOBAL_CONTENTS.cancel[local]}
                          square
                          size="md"
                          onClick={handleContainerClick}
                        />
                        <Button
                          label={componentProps.confirmLabel || GLOBAL_CONTENTS.confirm[local]}
                          baseline="primary"
                          size="md"
                          onClick={handdleConfirm}
                          square
                        />
                      </Box>
                    </Box>
                  </ModalContainer>
                )
              }

              case 'Info': {
                return (
                  <ModalContainer
                    onClick={() => {
                      if (closeOnOutsideClick) {
                        handleContainerClick()
                      }
                    }}
                    overlayClassName={overlayClassName}
                    key={`modal-container-${key}`}
                  >
                    <ModalContentContainer onClick={handleContentClick}>
                      <Box className={pb8}>
                        <Text size="lg">{dialog.componentProps.label}</Text>
                      </Box>
                      <Box flex alignRight className={mt16}>
                        <Button
                          label="Confirm"
                          baseline="primary"
                          size="xs"
                          onClick={handleContainerClick}
                          square
                        />
                      </Box>
                    </ModalContentContainer>
                  </ModalContainer>
                )
              }

              default: {
                const Component = this.components[type as any]

                return (
                  <ModalContainer
                    onClick={() => {
                      if (closeOnOutsideClick) {
                        handleContainerClick()
                      }
                    }}
                    key={`modal-container-${key}`}
                    position={dialog.position}
                    overlayClassName={overlayClassName}
                  >
                    <Box
                      className={classes(dialog.containerClassName || '', STYLES.container)}
                      rounded
                      relative
                      fullWidth={dialog.fullWidth}
                      fullHeight={dialog.fullHeight}
                      onClick={handleContentClick}
                    >
                      {dialog.title && (
                        <Box className={classes(p12, containerTitle)} onlyContrast fullWidth>
                          <Text size="lg" semiBold>
                            {dialog.title || 'Modal'}
                          </Text>
                        </Box>
                      )}
                      <Component
                        key={`modal-content-${key}`}
                        {...componentProps}
                        handleContentClick={handleContentClick}
                        pop={self.pop}
                      />
                    </Box>
                  </ModalContainer>
                )
              }
            }
          })}
        </>
      </CSSTransition>
    )
  }
}

/* Styled Components ============================================================================ */
const ModalContentContainer: any = (props: any) => (
  <div className={STYLES.containerModalContent} {...props}>
    {props.children}
  </div>
)

export const ModalContainer = (props: any) => {
  let positionObj = {}

  switch (props.position) {
    case 'top left': {
      positionObj = {
        alignLeft: true,
        alignTop: true,
      }
      break
    }
    default:
      positionObj = {
        alignCenterY: true,
        alignCenterX: true,
      }
      break
  }

  return (
    <Box
      className={classes(STYLES.containerModal, props.overlayClassName)}
      {...props}
      {...positionObj}
    >
      {props.children}
    </Box>
  )
}

export const ModalActionContainer = (props: any) => (
  <div className={STYLES.containerModalActions} {...props}>
    {props.children}
  </div>
)

const STYLES = stylesheet({
  containerModal: {
    background: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    padding: 8,
    position: 'fixed',
    top: 0,
    transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
    width: '100%',
    zIndex: 15,
    $nest: {
      '&.popin-enter': {
        opacity: 0,
        transform: 'translateY(-8px)',
      },

      '&.popin-enter-active': {
        opacity: 1,
        transform: 'translateY(0px)',
      },

      '&.popin-exit': {
        opacity: 1,
        transform: 'translateY(0px)',
      },

      '&.popin-exit-active': {
        opacity: 0,
        transform: 'translateY(-8px)',
      },
    },
  },
  containerModalContent: {
    background: 'white',
    borderRadius: 8,
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
    padding: 16,
  },
  containerModalActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  container: {
    ...createMediaQuery['md']({
      maxWidth: '100%',
      minWidth: 'initial',
      width: '100%',
    }),
  },
})

export const Modal = new ModalStack()
