import { useLayoutEffect } from 'react'
import { cssRaw, setStylesTarget } from 'typestyle'

/* Core ========================================================================================= */
import Router from './router'

/* Context ====================================================================================== */
import { GlobalContextProvider } from './contexts/global-context'

/* Components =================================================================================== */
import { Notification } from './components/notification'
import { ThemeProvider } from './components/theme'
import { HelpFlagProvider } from './modules/components/help-component'

cssRaw(`
.print {
  display: none;
}

html, body {
  height: 100%;
  opacity: 1 !important;
}

.material-icons, .material-icons-two-tone {
  font-size: 20px;
  transform: rotate(0.03deg);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  vertical-align: top;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial;
}

button, input, optgroup, select, textarea {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: initial;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

[type=submit], [type=reset], button, html [type=button] {
  -webkit-appearance: initial;
}

@media print
{

  body {
    background: white;
  }

  .no-print {
    display: none !important;
  }

  .print {
    display: initial !important;
  }
}

#webpack-dev-server-client-overlay { display: none;}
`)

/* <App /> ====================================================================================== */
function App(props: any) {
  const { state = {}, cookies } = props

  useLayoutEffect(() => {
    const stylesTarget: any = document.getElementById('styles-target')
    setStylesTarget(stylesTarget)
  }, [])

  return (
    <GlobalContextProvider cookies={cookies}>
      <HelpFlagProvider>
        <ThemeProvider>
          <Router state={state} />
          <Notification.render />
        </ThemeProvider>
      </HelpFlagProvider>
    </GlobalContextProvider>
  )
}

export default App
