
import { media, types } from 'typestyle'
import { LAYOUT_SM, LAYOUT_MD, LAYOUT_LG, LAYOUT_XL, LAYOUT_XS } from './style-class.constants'

export const createMediaQuery: { [key: string]: (style: types.CSSProperties) => any } = {
  xs: (style: types.CSSProperties) => {
    return media({ maxWidth: LAYOUT_XS }, style).$nest
  },
  sm: (style: types.CSSProperties) => {
    return media({ maxWidth: LAYOUT_SM }, style).$nest
  },
  md: (style: types.CSSProperties) => {
    return media({ maxWidth: LAYOUT_MD }, style).$nest
  },
  lg: (style: types.CSSProperties) => {
    return media({ maxWidth: LAYOUT_LG }, style).$nest
  },
  xl: (style: types.CSSProperties) => {
    return media({ maxWidth: LAYOUT_XL }, style).$nest
  },
}

export const createCustomMediaQuery = (props: {
  maxWidth?: number
  minWidth?: number
  style: any
}) => {
  if (props.minWidth) {
    return media({ minWidth: props.minWidth }, props.style).$nest
  }
  return media({ maxWidth: props.maxWidth }, props.style).$nest
}
