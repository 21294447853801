/**
 * Copyright 2022 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'

import * as serviceWorker from './__aeki__/service-worker'
import App from './__aeki__/app'
import './fields'
import posthog from 'posthog-js'

posthog.init('phc_GylwqTr5eyqXSNF6g7sXW5K1YMPYB4DInoKZu27qdQ8', {
  api_host: 'https://us.i.posthog.com',
})

loadableReady(() => {
  const container: any = document.getElementById('root')

  hydrateRoot(
    container,
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  )
})

serviceWorker.unregister()
