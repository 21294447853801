import { useContext, useEffect } from 'react'

import * as React from 'react'
import { classes } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Constants ==================================================================================== */
import { HEADER_HEIGHT, NAVBAR_WIDTH, TOOLBAR_HEIGHT, TOPBAR_HEIGHT } from './app-layout.constants'

/* Styles ======================================================================================= */
import { contentClass } from './content.class'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'

/* Types ======================================================================================== */
type ContentProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  context: React.Context<AppContext>
}

const defaultProps: object = {}

/* <Content /> ================================================================================= */
const ContentComponent: React.FC<ContentProps> = props => {
  const { className, children, style, context } = props
  const appLayoutContext = useContext(context)
  const { components, $components } = appLayoutContext
  const { navbar, header, topbar, toolbar, sidebar } = components
  const [active, $active] = useBoolean(true)
  const show = () => $active.set(true)
  const hide = () => $active.set(false)

  useEffect(() => {
    $components.patch('view', {
      active,
      $active,
      show,
      hide,
    })
  }, [active])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 })
    }
  }, [])

  const getWidth = () => {
    const widthOffset = getLeftOffset()
    let left = 0

    if (navbar && navbar.active && navbar.visible) {
      left += NAVBAR_WIDTH
    }

    return `calc(100% - ${widthOffset + left}px)`
  }

  const getLeftOffset = () => {
    // let left = 0

    if (navbar && navbar.active && navbar.visible) {
      // left += NAVBAR_WIDTH
    }

    if (sidebar && sidebar.active) {
      // left += SIDEBAR_WIDTH - 24
    }

    return 0
  }

  const getTopOffset = () => {
    let top = 0

    if (topbar && topbar.active) {
      top += TOPBAR_HEIGHT
    }

    if (header && header.active && header.visible) {
      top += HEADER_HEIGHT
    }

    if (toolbar && toolbar.active) {
      top += TOOLBAR_HEIGHT
    }

    return top
  }

  const { base, proped } = contentClass.setProps({
    leftOffset: getLeftOffset(),
    topOffset: getTopOffset(),
    width: getWidth(),
  })

  return (
    <div
      className={classes(
        'aeki-content',
        base,
        proped.offsetted,
        className,
        // loaded && proped.loaded,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

ContentComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Content = ContentComponent
