/**
 * Copyright 2022 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { FC, useEffect, useCallback } from 'react'
import { classes, style } from 'typestyle'
import { useNavigate } from 'react-router-dom'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../../__aeki__/components/__core/component.types'

/* Hooks ======================================================================================== */
import useValue from '../../__aeki__/hooks/use-value'

/* Components =================================================================================== */
import { A } from '../../__aeki__/components/a'
import { Box } from '../../__aeki__/components/box/box.component'
import { Button } from '../../__aeki__/components/button'
import { Logo } from '../logo'
import { MobileMenu } from './components/mobile-menu'
import { LocationSearch } from './components/location-search'
import { UserMenu } from './components/user-menu'

/* Styles ======================================================================================= */
import { f16i } from '../../__aeki__/styles/styleset/font-size/f16i'
import { h100 } from '../../__aeki__/styles/styleset/height/h100'
import { hide } from '../../__aeki__/styles/styleset/hide/hide'
import { hidesmi } from '../../__aeki__/styles/styleset/hide/hidesmi'
import { ml8 } from '../../__aeki__/styles/styleset/margin/ml8'
import { mr16 } from '../../__aeki__/styles/styleset/margin/mr16'
import { mr8 } from '../../__aeki__/styles/styleset/margin/mr8'
import { shadow as shadowCls } from '../../__aeki__/styles/styleset/shadow/shadow'
import { w100 } from '../../__aeki__/styles/styleset/width/w100'
import { paddingVerticalOuter } from '../../__aeki__/styles/default/padding'
import { flexsm } from '../../__aeki__/styles/styleset/flex/flexsm'
import { mr8sm } from '../../__aeki__/styles/styleset/margin/mr8sm'

/* App Layout Init ============================================================================== */
export const MAX_WIDTH = 1244

/* <Header /> =================================================================================== */
export const Header: FC<{
  context?: any
  shadow?: any
  maxWidth?: number
  menu?: any
  mobileMenu?: any
  userMenu?: any
  disableUserMenu?: boolean
  baseline?: ComponentBaselineType
  scrollEffect?: boolean
  allowToggleEffect?: boolean
  disablePadding?: boolean
  top?: number
}> = props => {
  const [_maxWidth, $maxWidth] = useValue(MAX_WIDTH)
  const [scrolled, $scrolled] = useValue(false)
  const [surface, $surface] = useValue(false)
  const history = useNavigate()

  const {
    shadow = true,
    maxWidth,
    baseline = 'surface',
    menu = [
      {
        label: 'Launch Search Tool',
        to: '/a/childcares',
        icon: 'map',
        isPrimary: true,
        buttonProps: {
          alt: true,
        },
      },
    ],
    disableUserMenu,
  } = props

  useEffect(() => {
    if (maxWidth) {
      $maxWidth.set(maxWidth)
    }
  }, [_maxWidth])

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        $scrolled.set(true)
      } else {
        $scrolled.set(false)
      }
    }

    if (props.scrollEffect) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (props.scrollEffect) {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  const renderLinks = useCallback(() => {
    return menu
      .filter((a: any) => a.isPrimary)
      .map((item: any, key: any) => {
        if (item.onClick || (item.buttonProps && item.buttonProps.onClick)) {
          return (
            <Button
              key={key}
              onClick={item.onClick}
              className={classes(mr8)}
              icon={
                item.icon ? (
                  <span className={classes('material-icons-two-tone', f16i)}>{item.icon}</span>
                ) : (
                  ''
                )
              }
              label={item.label}
              size="md"
              baseline={item.baseline || 'background'}
              square
              {...item.buttonProps}
            />
          )
        }
        return (
          <Button
            key={key}
            as={<A to={item.to} title={item.label} />}
            className={classes(mr8)}
            icon={
              item.icon ? (
                <span className={classes('material-icons-two-tone', f16i)}>{item.icon}</span>
              ) : (
                ''
              )
            }
            label={item.label}
            size="md"
            baseline={item.baseline || 'background'}
            square
          />
        )
      })
  }, [])

  return (
    <Box
      baseline={
        surface ? 'surface' : props.scrollEffect ? (scrolled ? baseline : 'none') : baseline
      }
      className={classes(
        surface ? style({ transition: 'none' }) : '',
        props.scrollEffect ? (scrolled ? `${shadow && shadowCls}` : '') : `${shadow && shadowCls}`,
      )}
      fullWidth
      fullHeight
      // style={{
      //   marginTop: 80,
      // }}
    >
      <Box
        className={classes(!props.disablePadding && paddingVerticalOuter)}
        baseline="none"
        fullWidth
        fullHeight
        // maxWidth={MAX_WIDTH}
        centerAutoX
      >
        <Box baseline="none" className={classes(w100, h100)} spaceBetween fullWidth alignCenterY>
          <Box
            baseline="none"
            className={classes(mr16)}
            flex
            fullHeight
            alignCenterY
            maxWidth={450}
            fullWidth
          >
            <Logo
              toggled={surface || !props.allowToggleEffect ? true : scrolled && props.scrollEffect}
            />
          </Box>
          <Box baseline="none" fullHeight flex alignCenterY maxWidth={450} fullWidth alignRight>
            <Box baseline="none" className={hidesmi} flex>
              {renderLinks()}
            </Box>
            {!disableUserMenu && (
              <UserMenu
                toggled={
                  surface || !props.allowToggleEffect ? true : scrolled && props.scrollEffect
                }
              />
            )}
            <LocationSearch
              className={classes(ml8, mr8sm)}
              style={{
                background: '#fece14',
              }}
              maxWidth={maxWidth}
              componentProps={{
                onSubmit: (text: string) => {
                  history(`/a/childcares?searchText=${text}`)
                },
                onSearchTextChange: (text: string) => {
                  history(`/a/childcares?searchText=${text}`)
                },
              }}
              onLocation={(coords: any) => {
                history(
                  `/a/childcares?center.0=${`${coords.longitude}`.replace(
                    '.',
                    '_',
                  )}&center.1=${`${coords.latitude}`.replace('.', '_')}`,
                )
              }}
              onToggle={(toggled: any) => {
                if (toggled) {
                  $surface.set(true)
                } else {
                  $surface.set(false)
                }
              }}
              isMobile
            />
            {menu.length > 0 && (
              <Box baseline="none" className={classes(hide, flexsm)}>
                <MobileMenu links={menu} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
