
import { useEffect, useRef } from 'react'
import { style, stylesheet } from 'typestyle'
import { CSSTransition } from 'react-transition-group'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Box } from '../box/box.component'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../style-class/style-class.utils'

/* Types ======================================================================================== */
type Sidebar = {
  render?: any
  label?: string
  props?: any
  type?: string
  componentProps?: any
}

/* SidebarStack =================================================================================== */
class SidebarStack {
  count = 0
  self: any = null
  storage: Sidebar[] = []
  components: any = {}

  setComponents = (components: any) => {
    this.components = components
  }

  add = (name: string, component: any) => {
    this.components[name] = component
  }

  push = (dialog: Sidebar) => {
    this.storage[this.count + 1] = dialog
    this.count++

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  pop = () => {
    delete this.storage[this.count]

    this.count--

    if (this.count < 0) {
      this.count = 0
    }

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  update = (props?: any) => {
    this.storage[this.count].props = {
      ...this.storage[this.count].props,
      ...props,
    }

    this.self.set(this)
  }

  size = () => this.count

  render = () => {
    const containerRef = useRef(null)
    const [self, $self] = useValue(this)
    this.self = $self

    useEffect(() => {
      if (self.count > 0) {
        document.documentElement.style.touchAction = 'none'
        document.body.style.touchAction = 'none'
        document.body.style.overflow = 'hidden'
      } else {
        document.documentElement.style.touchAction = ''
        document.body.style.touchAction = ''
        document.body.style.overflow = ''
      }
    }, [self])

    const handleContainerClick = () => {
      self.pop()
    }

    const handleContentClick = (event: any) => {
      event.stopPropagation()
    }

    return (
      <CSSTransition in={self.count > 0} timeout={300} classNames="popin" unmountOnExit>
        <>
          {self.storage.map((dialog: Sidebar, key: number) => {
            const { componentProps, type = 'Prompt' } = dialog

            const Component = this.components[type as any]

            return (
              <SidebarContainer key={key} onClick={handleContainerClick}>
                <Box
                  className={style({
                    width: 620,
                    height: '100%',
                    background: 'white',
                    overflow: 'auto',
                    $nest: {
                      ...createMediaQuery.md({
                        width: '100%',
                      }),
                    },
                  })}
                  ref={containerRef}
                  onClick={(e: any) => {
                    e.stopPropagation()
                  }}
                >
                  <Component
                    key={key}
                    {...componentProps}
                    containerRef={containerRef}
                    handleContentClick={handleContentClick}
                    pop={self.pop}
                  />
                </Box>
              </SidebarContainer>
            )
          })}
        </>
      </CSSTransition>
    )
  }
}

/* Styled Components ============================================================================ */
const SidebarContentContainer: any = (props: any) => (
  <div className={STYLES.containerSidebarContent} {...props}>
    {props.children}
  </div>
)

export const SidebarContainer = (props: any) => {
  return (
    <Box className={STYLES.containerSidebar} {...props}>
      {props.children}
    </Box>
  )
}

export const SidebarActionContainer = (props: any) => (
  <div className={STYLES.containerSidebarActions} {...props}>
    {props.children}
  </div>
)

const STYLES = stylesheet({
  containerSidebar: {
    background: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
    width: '100%',
    zIndex: 20,
    alignItems: 'flex-end',
    $nest: {
      '&.popin-enter': {
        opacity: 0,
        transform: 'translateX(8px)',
      },

      '&.popin-enter-active': {
        opacity: 1,
        transform: 'translateX(0px)',
      },

      '&.popin-exit': {
        opacity: 1,
        transform: 'translateX(0px)',
      },

      '&.popin-exit-active': {
        opacity: 0,
        transform: 'translateX(8px)',
      },
    },
  },
  containerSidebarContent: {
    background: 'white',
    borderRadius: 8,
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
    padding: 16,
  },
  containerSidebarActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  container: {
    ...createMediaQuery['md']({
      maxWidth: '100%',
      minWidth: 'initial',
      width: '100%',
    }),
  },
})

export const Sidebar = new SidebarStack()
