import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for search
   */
  base: string
  /**
   * Themed class for search base
   */
  themed: string
  proped: {
    focused: string
  }
  input: string
  inputSized: {
    lg: string
    md: string
    sm: string
    xl: string
    xs: string
  }
  button: {
    lg: string
    md: string
    sm: string
    xl: string
    xs: string
  }
}

type ClassProps = {}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const base = style({
    alignItems: 'center',
    borderRadius: 8,
    display: 'flex',
    paddingRight: 8,
    width: '100%',
  })

  const themed = style({
    // background: theme.getBaseline('surface').color.medium,
    transition: theme.transition.medium,
    transitionProperty: 'width',
  })

  const proped = {
    focused: style({
      width: '100%',
    }),
  }

  const input = style({
    background: 'none',
    border: 'none',
    fontSize: 12,
    marginLeft: 8,
    outline: 'none',
    width: '100%',
    $nest: {
      '&::placeholder': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'auto',
        width: '100%',
      },
    },
  })

  const button = {
    xs: style({
      height: '20px !important',
      fontSize: 16,
      $nest: {
        '&:hover': {
          background: 'rgb(220,220,220) !important',
        },
      },
    }),
    sm: style({
      height: '28px !important',
      fontSize: 16,
      $nest: {
        '&:hover': {
          background: 'rgb(220,220,220) !important',
        },
      },
    }),
    md: style({
      height: '32px !important',
      fontSize: 16,
      $nest: {
        '&:hover': {
          background: 'rgb(220,220,220) !important',
        },
      },
    }),
    lg: style({
      height: '36px !important',
      fontSize: 16,
      $nest: {
        '&:hover': {
          background: 'rgb(220,220,220) !important',
        },
      },
    }),
    xl: style({
      height: '40px !important',
      fontSize: 16,
      $nest: {
        '&:hover': {
          background: 'rgb(220,220,220) !important',
        },
      },
    }),
  }

  const inputSized = {
    xs: style({ height: 20, fontSize: 10 }),
    sm: style({ height: 28, fontSize: 11 }),
    md: style({ height: 32, fontSize: 12 }),
    lg: style({ height: 36, fontSize: 13 }),
    xl: style({ height: 44, fontSize: 16 }),
  }

  return {
    base,
    button,
    themed,
    input,
    inputSized,
    proped,
  }
}

/* Export ======================================================================================= */
export const searchClass = new StyleClass<ClassNames, ClassProps>(getNames)
