/**
 * SelectComponent
 *
 * Write CSS with following as reference
 *  <Field> // from withField()
 *    <FieldContainer className='aeki-tfc'>
 *      <input className='aeki-tfi'/>
 *    </FieldContainer>
 *  </Field>
 */
import { useRef, useEffect, FC } from 'react'

import * as React from 'react'
import { classes, style as _style } from 'typestyle'

import config from '../../../config'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'
import useCounter from '../../hooks/use-counter'
import useDebounce from '../../hooks/use-debounce'
import useValue from '../../hooks/use-value'
import useLocal from '../../hooks/use-local'
import { useArray } from '../../hooks/use-array'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'
import { FormActions, FormContext } from '../form/index'

/* Components =================================================================================== */
import OutsideClickHandler from 'react-outside-click-handler'
import { Box } from '../box/box.component'
import { Button } from '../button'
import { Text } from '../text/text.component'
import { fieldClass } from '../field/field.class'
import { TextField } from '../text-field'
import { Loading } from '../loading'
import { FloatNew } from '../float-new'

/* Types ======================================================================================== */
import { ComponentBaselineType, ComponentCornerPositionType } from '../__core/component.types'

/* Styles ======================================================================================= */
import { f14i } from '../../styles/styleset/font-size/f14i'
import { noWrap } from '../../styles/styleset/no-wrap/noWrap'
import { p8 } from '../../styles/styleset/padding/p8'
import { selectClass } from './select.class'
import { pl12 } from '../../styles/styleset/padding/pl12'
import { f18i } from '../../styles/styleset/font-size/f18i'
import { pr8 } from '../../styles/styleset/padding/pr8'
import { GLOBAL_CONTENTS } from '../../common/global-contents'

type FieldPropsWithAny =
  | FieldProps
  | {
      options?: any[]
    }

export type SelectProps = {
  allowEmpty?: boolean
  baseline?: ComponentBaselineType
  options?: any
  renderInitialValue?: (value: any) => any
  renderOption?: (option: any) => any
  renderValue?: (value: any) => any
  renderPlaceholder?: (value: any) => any
  anchorPosition?: ComponentCornerPositionType
  dialogPosition?: ComponentCornerPositionType
  focused?: boolean
  buttonProps?: any
  disableIcon?: boolean
  fixed?: boolean
  width?: string
  fullWidth?: boolean
  square?: boolean
  onGhostChange?: any
  formRef?: any
  searchMessage?: string
  noMatchesMessage?: string
  searchEnabled?: boolean
  search?: (term: string) => any
  flip?: boolean
  translate?: boolean
} & FieldPropsWithAny

/* <SelectComponent /> ======================================================================= */
/**  See bottom for wrapped component */
const SelectComponent: React.FC<FieldContextProps & SelectProps> = props => {
  const [local] = useLocal()
  const {
    className,
    allowEmpty,
    baseline = 'background',
    dispatch,
    name,
    onBlur,
    onChange,
    onGhostChange,
    onFocus,
    options,
    renderInitialValue,
    handleValidate,
    handleValue,
    renderOption,
    renderValue,
    renderPlaceholder,
    size = 'xl',
    buttonProps,
    field = {
      focused: undefined,
    },
    fullWidth,
    square = true,
    searchEnabled = false,
    translate,
    search = term =>
      options.filter((option: any) => {
        const text = typeof option === 'object' ? option.label : option
        return text?.toLowerCase().includes(term?.toLowerCase() || '')
      }),
    searchMessage = GLOBAL_CONTENTS.searchMessage[local],
    noMatchesMessage = GLOBAL_CONTENTS.noMatchMessage[local],
    disableIcon,
    flip,
  } = props

  const inputRef = useRef(null)
  const [value, $value] = useValue(props.value || '')
  const [selected, $selected] = useCounter(-1, { min: 0, max: options?.length })
  const [focused, $focused] = useValue(props.focused || false)
  const [filteredOptions, $filteredOptions] = useArray(options)
  const [searchValue, $searchValue] = useValue(searchEnabled ? props.value : '')
  const debouncedSearchValue = useDebounce(searchValue, 200)
  const [loading, $loading] = useBoolean(true)
  const formContext = React.useContext(FormContext)
  const { form } = formContext.value

  /* Field ClassNames --------------------------------------------------------------------------- */
  const { fieldSized } = fieldClass.setProps({
    size,
    square,
  })

  /* ClassNames --------------------------------------------------------------------------------- */
  const { base, themed, proped, option, placeholder } = selectClass.setProps({
    baseline: props.disabled ? 'disabled' : baseline,
    disabled: props.disabled,
  })

  useEffect(() => {
    if (dispatch && name) {
      dispatch({ type: FormActions.FIELD, param: { name, value: { validate: props.validate } } })
    }
  }, [])

  useEffect(() => {
    async function setDefaultValue() {
      if (props.defaultValue) {
        if (onChange) {
          onChange(await handleValidate(props.defaultValue))
        }

        if (onGhostChange) {
          onGhostChange(await handleValidate(props.defaultValue))
        }

        if (dispatch) {
          dispatch({
            type: FormActions.SET,
            param: { name, value: await handleValidate(props.defaultValue) },
          })
          dispatch({ type: FormActions.BLUR, param: { name } })
        }
      }
    }

    if (!props.value && !props.formValue) {
      setDefaultValue()
    }
  }, [props.defaultValue, props.value, props.formValue])

  useEffect(() => {
    $value.set(props.value)
  }, [props.value])

  useEffect(() => {
    $value.set(props.formValue || '')
  }, [props.formValue])

  useEffect(() => {
    $focused.set(field.focused)
  }, [field.focused])

  useEffect(() => {
    if (props.name && form[props.name] === null) {
      $searchValue.set('')
      $value.set('')
    }
  }, [props.name && form[props.name]])

  useEffect(() => {
    if (focused) {
      // ; (selectRef.current as any).focus()
    }
  }, [focused])

  useEffect(() => {
    const parseOptions = async () => {
      $loading.set(true)
      const parsedOptions = (await options) || []
      if (renderInitialValue && parsedOptions.length > 0) {
        if (value) {
          handle('change')(renderInitialValue(value))
        }
      }

      if (parsedOptions?.length > 0) {
        $selected.setMax(options.length)
      }

      $filteredOptions.set(parsedOptions)
      $loading.set(false)
    }

    parseOptions().catch(console.error)
  }, [options])

  useEffect(() => {
    const doSearch = async () => {
      $loading.set(true)
      const options = await search(debouncedSearchValue)
      $loading.set(false)
      $filteredOptions.set(options)
    }

    if (searchEnabled) {
      doSearch().catch(console.error)
    }
  }, [debouncedSearchValue])

  /* Event Handlers ----------------------------------------------------------------------------- */
  const handle: (type: string) => any = type => {
    switch (type) {
      case 'change':
        return async (item: any) => {
          if (onChange) {
            onChange(await handleValidate(item))
          }

          if (onGhostChange) {
            onGhostChange(await handleValidate(item))
          }

          if (dispatch && name) {
            dispatch({ type: FormActions.SET, param: { name, value: await handleValidate(item) } })
            dispatch({ type: FormActions.BLUR, param: { name } })
          }

          $focused.set(false)

          $value.set(item)
        }
      case 'focus':
        return (event: any) => {
          event.preventDefault()

          if (!props.disabled) {
            if (onFocus) {
              onFocus()
            }

            $focused.set(true)
          }
        }
      case 'blur':
        return () => {
          if (onBlur) {
            onBlur()
          }

          $focused.set(false)
        }
      case 'keyDown':
        return (event: any) => {
          // event.keyCode
          switch (event.keyCode) {
            // down
            case 40:
              $selected.increment()
              break
            // up
            case 38:
              $selected.decrement()
              break
            case 13:
              event.preventDefault()
              if (selected > -1) {
                handle('change')(options[selected])
                $selected.reset()
              }
              break
          }
        }
      default:
        throw new Error('Unexpected event handler')
    }
  }

  const handleClose = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    handle('change')('')
  }

  const renderOptions = () => {
    if (value) {
      const parsedValue =
        translate && config.dictionary?.[value.label || value]?.[local]
          ? config.dictionary?.[value.label || value]?.[local]
          : value.label || value
      if (renderValue) {
        return (
          <Text className={noWrap} size={'md'} baseline={props.disabled ? 'disabled' : 'none'}>
            {renderValue(handleValue(parsedValue))}
          </Text>
        )
      }

      return (
        <Text className={noWrap} size={'md'} baseline={props.disabled ? 'disabled' : 'none'}>
          {handleValue(parsedValue)}
        </Text>
      )
    }

    if (renderPlaceholder) {
      return renderPlaceholder(props.placeholder)
    }

    return (
      <Text
        size={'sm'}
        className={classes(placeholder, noWrap)}
        baseline={props.disabled ? 'disabled' : 'none'}
      >
        {props.placeholder}
      </Text>
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handle('blur')}>
      <Box className={className} baseline="none">
        <FloatNew
          flip={flip}
          fullWidth={fullWidth}
          open={focused}
          content={
            <Box
              className={classes(_style({ width: fullWidth ? '100%' : 'fit-content' }))}
              baseline="surface"
              rounded
              shadow
              flex
              row
            >
              {searchEnabled && (
                <Box className={classes(p8)} alignCenterY baseline="surface" rounded>
                  <TextField
                    freeSize
                    autoFocus
                    type="search"
                    value={searchValue}
                    placeholder={searchMessage}
                    className={classes(_style({ opacity: '1' }))}
                    onChange={value => {
                      $searchValue.set(value)
                    }}
                    onKeyDown={handle('keyDown')}
                  />
                </Box>
              )}
              <Box
                baseline="surface"
                className={classes(_style({ width: fullWidth ? '100%' : 'fit-content' }))}
                maxHeight={200}
                rounded
                flex
                scroll
                row
              >
                {loading && (
                  <Box className={classes(p8)} alignCenterY baseline="surface" rounded>
                    <Loading />
                    <Text size="sm">Loading options&hellip;</Text>
                  </Box>
                )}
                {filteredOptions?.map((item: any, key: number) => {
                  const label =
                    translate && config.dictionary?.[item.label || item]?.[local]
                      ? config.dictionary?.[item.label || item]?.[local]
                      : item.label || item

                  return renderOption ? (
                    <Box
                      key={key}
                      onClick={() => {
                        handle('change')(item)
                      }}
                    >
                      {renderOption(item)}
                    </Box>
                  ) : (
                    <Box
                      className={classes(p8, option)}
                      onClick={() => {
                        handle('change')(item)
                      }}
                      alignCenterY
                      key={key}
                      baseline={selected === key ? 'background' : 'surface'}
                      rounded
                    >
                      <Text
                        size="sm"
                        className={noWrap}
                        baseline={props.disabled ? 'disabled' : 'none'}
                      >
                        {label}
                      </Text>
                    </Box>
                  )
                })}
                {!filteredOptions?.length && (
                  <Box
                    className={classes(p8, _style({ whiteSpace: 'nowrap' }))}
                    alignCenterY
                    baseline="surface"
                    rounded
                  >
                    <Text size="sm" baseline="none">
                      {noMatchesMessage}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          }
        >
          <Box
            className={classes('aeki-select', base, fieldSized[size], themed)}
            ref={inputRef}
            rounded
          >
            {/* Value and Buttons -------------------------------------------------------------------- */}
            <Box
              className={focused && proped.focused}
              baseline="none"
              onClick={handle('focus')}
              alignCenterY
              spaceBetween
              fullHeight
              fullWidth
              rounded
            >
              <Box
                className={classes(pl12, pr8)}
                alignCenterY
                fullHeight
                fullWidth
                spaceBetween
                baseline="none"
                rounded
                {...buttonProps}
              >
                {renderOptions()}
              </Box>
              <Box alignCenterY fullHeight baseline="none">
                {handleValue(value) && allowEmpty && !props.disabled && (
                  <>
                    <Box alignCenterY fullHeight baseline="none">
                      <Button
                        baseline="none"
                        // className={z2}
                        icon={
                          <span
                            className={classes(process.env.REACT_APP_GOOGLE_MATERIAL_ICONS, f14i)}
                          >
                            close
                          </span>
                        }
                        size={'xs'}
                        square
                        onClick={handleClose}
                      />
                    </Box>
                  </>
                )}
                {!props.disabled && !disableIcon && (
                  <Button
                    icon={
                      <span className={classes(process.env.REACT_APP_GOOGLE_MATERIAL_ICONS, f18i)}>
                        keyboard_arrow_down
                      </span>
                    }
                    size={'sm'}
                    baseline="none"
                    ariaLabel="Toggle Dropdown"
                    square
                  />
                )}
              </Box>
              {/* <input className={w100} onFocus={handle('focus')} onKeyDown={handle('keyDown')} /> */}
            </Box>
          </Box>
        </FloatNew>
      </Box>
    </OutsideClickHandler>
  )
}

export const Select: FC<SelectProps> = withField(SelectComponent)
