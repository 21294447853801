import { createContext, useContext, useEffect, useReducer } from 'react'

import * as React from 'react'

/* Hooks ======================================================================================== */
import { flat, structureObj } from '../../share/obj-equal'
import { fieldClass } from '../field/field.class'
import { Box } from '../box/box.component'
import { stylesheet } from 'typestyle'
import { get, set } from '../../share/find'

/* Types ======================================================================================== */
export type Form = {
  form?: any
  $form?: any
  fields?: any
  $fields?: any
  temp?: any
  $temp?: any
  meta?: any
  $meta?: any
  hasError?: any
  $hasError?: any
  validate: (name?: string) => boolean
  set: any
}

export type FieldValue = string | { value: any; error: string }

/* Components =================================================================================== */
const FormWrapper = (props: any) => {
  const { children } = props
  return (
    <Box className={STYLES.container} rounded>
      {children}
    </Box>
  )
}

const STYLES = stylesheet({
  container: {
    alignItems: 'inherit',
    display: 'inherit',
    height: 'inherit',
    justifyContent: 'inherit',
    width: '100%',
    flexDirection: 'inherit',
    $nest: {
      form: {
        alignItems: 'inherit',
        display: 'inherit',
        height: 'inherit',
        justifyContent: 'inherit',
        width: 'inherit',
        flexDirection: 'inherit',
        $nest: {
          [`.${fieldClass.names.base}`]: {
            marginBottom: 8,
          },
        },
      },
    },
  },
})

/* Context ====================================================================================== */
type FormContextState = {
  form: { [key: string]: any }
  raw: { [key: string]: any }
  fields: { [key: string]: any }
  fieldArray: { [key: string]: any }
}

const initialState: FormContextState = {
  form: {},
  raw: {},
  fields: {},
  fieldArray: {},
}

export const FormActions = {
  RESET: 'reset',
  DELETE: 'delete',
  SET: 'set',
  GET: 'get',
  FOCUS: 'focus',
  BLUR: 'blur',
  FIELD: 'field',
  FIELDS: 'fields',
  FIELD_ARRAY: 'field_array',
  RAW: 'raw',
  RAW_FIND: 'raw_find',
  RAW_PATCH: 'raw_patch',
  RAW_RESET: 'raw_reset',
  FORM_RESET: 'form_reset',
  CLEAN: 'clean',
}

function beforeSubmit(data?: any, form?: any, fields?: any, fieldArray?: any) {
  const populate = [
    ...((data || form).populate && (data || form).populate.length ? (data || form).populate : []),
  ]

  if (data) delete data?.populate
  if (form) delete form?.populate

  const temp: any = { ...flat(JSON.parse(JSON.stringify(data || form))) }

  for (const key in fields) {
    const value: FieldValue = temp[key]

    if (!(fields as any)[key]) {
      continue
    }

    const beforeSubmit = (fields as any)[key].beforeSubmit

    if (beforeSubmit && beforeSubmit(value, temp)) {
      temp[key] = beforeSubmit(value, temp)
    }

    if (beforeSubmit && beforeSubmit(value, temp) && beforeSubmit(value, temp).__DELETE) {
      delete temp[key]
    }

    if (temp[key] === undefined) {
      delete temp[key]
    }

    if (temp[key] && typeof temp[key] === 'object' && Object.keys(temp[key]).length === 0) {
      delete temp[key]
    }
  }

  const structured: any = { ...structureObj(temp) }

  for (const key in fieldArray as any) {
    const { beforeSubmit } = (fieldArray as any)[key as any] || []

    if (beforeSubmit) {
      if (get(key, structured, 'array', []).length > 0) {
        set(
          key,
          structured,
          beforeSubmit(JSON.parse(JSON.stringify(get(key, structured, 'array', [])))),
        )
      }
    }
  }

  structured.populate = populate

  return structured
}

const reducer = (state = initialState, action: { type: string; param?: any }) => {
  switch (action.type) {
    case FormActions.RESET: {
      return {
        form: {},
        raw: {},
        fields: {},
        fieldArray: {},
        cleanedData: {},
      }
    }
    case FormActions.FORM_RESET: {
      return {
        ...state,
        form: {},
      }
    }
    case FormActions.SET: {
      const { name, value } = action.param
      const form = name
        ? {
          ...state.form,
          [name]: value,
        }
        : value

      return {
        ...state,
        form,
      }
    }

    case FormActions.DELETE: {
      const { name, regex } = action.param

      const newForm = { ...state.form }
      const newFields = { ...state.fields }

      if (regex) {
        for (const key in newForm) {
          if (key.match(regex)) {
            delete (newForm as any)[key]
          }
        }
      } else {
        delete (newForm as any)[name]
      }

      return {
        ...state,
        form: newForm,
        fields: newFields,
      }
    }
    case FormActions.FOCUS: {
      const { name } = action.param
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...(state.fields as any)[name],
            focused: true,
          },
        },
      }
    }

    case FormActions.BLUR: {
      const { name } = action.param
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...(state.fields as any)[name],
            focused: false,
          },
        },
      }
    }

    case FormActions.FIELDS: {
      const { value } = action.param

      return {
        ...state,
        fields: value,
      }
    }

    case FormActions.FIELD: {
      const { name, value } = action.param

      if (!name) {
        return state
      }
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...(state.fields ? (state.fields as any)[name] : {}),
            ...value,
          },
        },
      }
    }

    case FormActions.FIELD_ARRAY: {
      const { name, beforeSubmit } = action.param

      if (!name) {
        return state
      }
      return {
        ...state,
        fieldArray: {
          ...state.fieldArray,
          [name]: {
            beforeSubmit,
          },
        },
      }
    }

    case FormActions.RAW_RESET: {
      return {
        ...state,
        raw: {},
      }
    }

    case FormActions.RAW: {
      const { name, value } = action.param

      return {
        ...state,
        raw: name
          ? {
            ...state.raw,
            [name]: value,
          }
          : value,
      }
    }

    case FormActions.RAW_FIND: {
    }

    case FormActions.RAW_PATCH: {
    }

    case FormActions.CLEAN: {
      const { form, fields, fieldArray } = state

      return {
        ...state,
        cleanedData: beforeSubmit(form, form, fields, fieldArray),
      }
    }

    default:
      throw new Error('Unexpected action')
  }
}

export const FormContext = createContext({
  value: initialState,
  dispatch: null,
})

/* Form ========================================================================================= */
export const Form: React.FC<{
  className?: string
  name?: string
  onChange?: (data: any, fields: any, dispatch) => void
  onSubmit?: (data: any) => void
  onMetaChange?: (data: any) => void
  values?: object
  defaultValues?: object
  excludeStructure?: any[]
  withoutForm?: boolean
  ref?: any
  reset?: any
  onValueLoaded?: () => void
  children?: any
}> = React.forwardRef(
  ({ children, values, onSubmit, onChange, onValueLoaded, className, withoutForm, reset }, ref) => {
    const [value, dispatch] = useReducer(reducer, initialState)
    const { form, fields, fieldArray } = value

    useEffect(() => {
      if (values !== undefined) {
        dispatch({ type: FormActions.SET, param: { value: flat(values) } })
        dispatch({ type: FormActions.RAW, param: { value: values } })

        onValueLoaded?.()
      }
    }, [values])

    useEffect(() => {
      if (onChange) {
        onChange(form, fields, dispatch)
      }
    }, [form])

    useEffect(() => {
      if (reset) {
        dispatch({ type: FormActions.RESET })
      }
    }, [reset])

    const contextValue = {
      value,
      dispatch,
    }

    const validate = async () => {
      let hasError = false

      for (const key in fields) {
        const value: FieldValue = form[key]
        let error

        if (!(fields as any)[key]) {
          continue
        }

        let validators = (fields as any)[key].validate

        if (typeof validators === 'function') {
          validators = await validators(form)
        }

        if (validators) {
          for (let i = 0; i < validators.length; i++) {
            if (typeof value === 'object' && value.error) {
              error = await validators[i](value.value)
            } else {
              error = await validators[i](value)
            }

            if (error) {
              hasError = true
              if (typeof value === 'object' && value.error) {
                dispatch({ type: FormActions.SET, param: { name: key, value } })
              } else {
                dispatch({ type: FormActions.SET, param: { name: key, value: { value, error } } })
              }
              break
            } else {
              if (typeof value === 'object' && value.error) {
                dispatch({ type: FormActions.SET, param: { name: key, value: value.value } })
              }
            }
          }
        }
      }
      return hasError
    }

    const handleSubmit = async (data?: any) => {
      const hasError = await validate()

      if (onSubmit && !hasError) onSubmit(beforeSubmit(data, form, fields, fieldArray))
    }

    if (ref && (ref as any).current) {
      ; (ref as any).current.handleSubmit = handleSubmit
        ; (ref as any).current.dispatch = dispatch
    }

    return (
      <FormContext.Provider value={contextValue as any}>
        <FormWrapper>
          {withoutForm ? (
            <div className={className}>{children}</div>
          ) : (
            <form
              ref={ref as any}
              className={className}
              onSubmit={async (e: any) => {
                e.preventDefault()
                await handleSubmit()
              }}
            >
              {children}
            </form>
          )}
        </FormWrapper>
      </FormContext.Provider>
    )
  },
)

export const withForm: (Component: any) => React.FC<any> = Component => {
  const ComposedComponent: React.FC<any> = props => {
    const formContext = useContext(FormContext)

    return (
      <>
        <Component {...props} {...formContext} />
      </>
    )
  }

  return ComposedComponent
}
