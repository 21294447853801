import { FC, useContext, useEffect } from 'react'

import * as React from 'react'
import { classes, media, style } from 'typestyle'

import { TOPBAR_HEIGHT } from './app-layout.constants'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'
import { Box } from '../box/box.component'

export const Topbar: FC<{
  children?: any
  context: React.Context<AppContext>
}> = ({ context, children }) => {
  const appLayoutContext = useContext(context)
  const { $components, config } = appLayoutContext
  const [active, $active] = useBoolean(config.topbar.active)

  const toggle = () => $active.toggle()

  useEffect(() => {
    $components.patch('topbar', { active, $active, toggle, visible: active })
  }, [])

  useEffect(() => {
    $components.patch('topbar', { active, $active, toggle, visible: active })
  }, [active])

  return (
    <Box
      className={classes(
        STYLES.container,
        style({
          top: active ? 0 : -TOPBAR_HEIGHT,
        }),
      )}
    >
      {children}
    </Box>
  )
}

const STYLES = {
  container: style({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 6,
    height: TOPBAR_HEIGHT,
    ...media(
      { type: 'print' },
      {
        display: 'none',
      },
    ),
  }),
}
