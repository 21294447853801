import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Themed class for line
   */
  baseThemed: string
}

type ClassProps = {
  horiz?: boolean
  dark?: boolean
  thick?: boolean
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { horiz, dark, thick } = props

  let baseThemed

  if (horiz) {
    baseThemed = style({
      width: thick ? 2 : 1,
      borderRadius: '2px !important',
      display: 'inline',
      background: dark ? theme?.boxes?.border?.dark : theme?.boxes?.border?.medium,
    })
  } else {
    baseThemed = style({
      height: thick ? 2 : 1,
      borderRadius: '2px !important',
      display: 'block',
      background: dark ? theme?.boxes?.border?.dark : theme?.boxes?.border?.medium,
    })
  }

  return {
    baseThemed,
  }
}

/* Export ======================================================================================= */
export const lineClass = new StyleClass<ClassNames, ClassProps>(getNames)
