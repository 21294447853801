/** Caution - This file is managed by aeki */
import loadable from '@loadable/component'
import { Route, useLocation } from 'react-router-dom'
import { Modal } from '../__aeki__/components/modal'

export const AsyncPage = (props: any) => {
  const { injection, name, component, state = {} } = props
  const location = useLocation()
  const Component = component || loadable(() => import(`./${name}`))

  function inject() {
    if (location.search.includes('auth=1')) {
      return {}
    }

    if (typeof globalThis.window !== undefined) {
      if ((globalThis.window as any) && (globalThis.window as any).__aeki__) {
        const injection = { ...(globalThis.window as any).__aeki__ }
        return injection || state
      }
    }

    return state
  }

  return (
    <>
      <Component
        injection={injection}
        inject={inject}
        eject={() => {
          ;(window as any).__aeki__ = null
        }}
      />
      <Modal.render />
    </>
  )
}

export default (state: any) => {
  return (
    <>
      <Route
        element={<AsyncPage name={'registration-form'} state={state} />}
        path={'/a/registration-form/:groupId/:rowId'}
      />
      <Route
        element={<AsyncPage name={'community-openings'} state={state} />}
        path={'/a/r/:id/openings'}
      />
      <Route element={<AsyncPage name={'docs-post'} state={state} />} path={'/a/docs/:id'} />
      <Route element={<AsyncPage name={'blog-post'} state={state} />} path={'/a/blog/:blogId'} />
      <Route
        element={<AsyncPage name={'childcares'} state={state} />}
        path={'/a/childcares/:placeId'}
      />
      <Route element={<AsyncPage name={'vacancy'} state={state} />} path={'/a/vacancy/:placeId'} />
      <Route
        element={<AsyncPage name={'vacancy-list'} state={state} />}
        path={'/a/vacancy-list/:groupId'}
      />
      <Route element={<AsyncPage name={'community-group'} state={state} />} path={'/a/r/:id'} />
      <Route element={<AsyncPage name={'__contact-us__'} state={state} />} path={'/a/contact-us'} />
      <Route element={<AsyncPage name={'docs'} state={state} />} path={'/a/docs'} />
      <Route element={<AsyncPage name={'blog'} state={state} />} path={'/a/blog'} />
      <Route
        element={<AsyncPage name={'childcares'} state={state} />}
        path={'/a/childcare-not-found'}
      />
      <Route element={<AsyncPage name={'childcares'} state={state} />} path={'/a/childcares'} />
      <Route
        element={<AsyncPage name={'attendance'} state={state} />}
        path={'/a/c/:id/attendance'}
      />
      <Route element={<AsyncPage name={'childcare'} state={state} />} path={'/a/c/:id'} />
      <Route element={<AsyncPage name={'posts'} state={state} />} path={'/a/p/:id'} />
      <Route
        element={<AsyncPage name={'pricing'} state={state} />}
        path={'/a/pricing'}
      />
      <Route
        element={<AsyncPage name={'claim-your-childcare'} state={state} />}
        path={'/a/claim-your-childcare'}
      />
      <Route element={<AsyncPage name={'places'} state={state} />} path={'/a/places'} />
      <Route element={<AsyncPage name={'__home__'} state={state} />} path={'/'} />
    </>
  )
}
