import { createContext, useContext, FC, useEffect } from 'react'
import useObject from '../../hooks/use-object'
import useValue from '../../hooks/use-value'
import { Baseline } from './baseline'
import { Box } from './box'
import { ComponentBaselineType } from '../__core/component.types'
import { DefaultTheme } from './theme.default'
import config from '../../../config'
import { cssRaw } from 'typestyle'
import useBoolean from '../../hooks/use-boolean'

export const ThemeContext = createContext(DefaultTheme)
export const useTheme = () => {
  const theme = useContext(ThemeContext)

  if (theme.length) return theme

  return [theme]
}

export const ThemeProvider: FC<{
  children?: any
  baselines?: Baseline[]
  boxes?: Box[]
  theme?: any
}> = ({ children, theme = DefaultTheme }) => {
  const [isInitialGlobalStyleSet, $isInitialGlobalStyleSet] = useBoolean()
  const [index, $index] = useValue(
    typeof window !== 'undefined'
      ? window.localStorage.getItem(`aeki-${process.env.REACT_APP_PROJECT_ID}-theme`) || 0
      : 0,
  )

  useEffect(() => {
    if (!isInitialGlobalStyleSet) {
      cssRaw(`
      body {
        background: ${config.theme[index].baselines.background.color.light};
      }
      `)
      $isInitialGlobalStyleSet.toggle()
    }
  }, [isInitialGlobalStyleSet])

  const [_theme, $_theme] = useObject(
    index
      ? {
          ...config.theme[index],
          getBaseline: (name: ComponentBaselineType, alt?: boolean) => {
            if (!name) {
              name = 'surface'
            }

            if (alt) {
              if ((config.theme[index] as any).baselines[`${name}Alt`]) {
                return (config.theme[index] as any).baselines[`${name}Alt`]
              }
            }

            return (config.theme[index] as any).baselines[name]
          },
        }
      : {
          ...theme,
          getBaseline: (name: ComponentBaselineType, alt?: boolean) => {
            if (!name) {
              name = 'surface'
            }

            if (alt) {
              if (theme.baselines[`${name}Alt`]) {
                return theme.baselines[`${name}Alt`]
              }
            }

            return theme.baselines[name]
          },
        },
  )

  const setTheme = (index: number) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(
        `aeki-${process.env.REACT_APP_PROJECT_ID}-theme`,
        JSON.stringify(index),
      )
    }

    $index.set(index)

    $_theme.set({
      ...config.theme[index],
      getBaseline: (name: ComponentBaselineType, alt?: boolean) => {
        if (!name) {
          name = 'surface'
        }

        if (alt) {
          if ((config.theme[index] as any).baselines[`${name}Alt`]) {
            return (config.theme[index] as any).baselines[`${name}Alt`]
          }
        }

        return (config.theme[index] as any).baselines[name]
      },
    })
  }

  return (
    <ThemeContext.Provider value={[_theme, setTheme, index]}>
      {_theme && children}
    </ThemeContext.Provider>
  )
}
