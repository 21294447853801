/**
 * Copyright 2022 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { classes } from 'typestyle'

/* Components =================================================================================== */
import { A } from '../../../__aeki__/components/a'
import { Box } from '../../../__aeki__/components/box/box.component'
import { Button } from '../../../__aeki__/components/button'
import { Dropdown } from '../../../__aeki__/components/dropdown'

/* Styles ======================================================================================= */
import { f16i } from '../../../__aeki__/styles/styleset/font-size/f16i'
import { mb4 } from '../../../__aeki__/styles/styleset/margin/mb4'
import { mr8 } from '../../../__aeki__/styles/styleset/margin/mr8'
import { w100 } from '../../../__aeki__/styles/styleset/width/w100'
import { z5 } from '../../../__aeki__/styles/styleset/z-index/z5'
import { p8 } from '../../../__aeki__/styles/styleset/padding/p8'

export const MobileMenu = (props: { links: { label: string; to: string; icon?: any }[] }) => {
  const { links } = props

  const renderContent = () => () => {
    return (
      <Box className={classes(p8, z5)} baseline="surface" rounded shadow minWidth={176} flex row>
        {links.map((link: any, key: any) => {
          if (link.to) {
            return (
              <A to={link.to} key={key}>
                <Button
                  icon={
                    link.icon ? (
                      <span className={classes('material-icons-two-tone', f16i)}>{link.icon}</span>
                    ) : (
                      ''
                    )
                  }
                  className={classes(
                    mr8,
                    links.length > 1 && key !== links.length - 1 && mb4,
                    w100,
                  )}
                  label={link.label}
                  size="md"
                  baseline="background"
                  square
                />
              </A>
            )
          }
          return (
            <Button
              key={key}
              icon={
                link.icon ? (
                  <span className={classes('material-icons-two-tone', f16i)}>{link.icon}</span>
                ) : (
                  ''
                )
              }
              className={classes(mr8, links.length > 1 && key !== links.length - 1 && mb4, w100)}
              label={link.label}
              onClick={link.onClick}
              size="md"
              baseline="background"
              square
            />
          )
        })}
      </Box>
    )
  }

  if (links.length === 0) {
    return <></>
  }

  return (
    <Dropdown
      floatProps={{
        width: 200,
        anchorPosition: 'bottom right',
        dialogPosition: 'top right',
        fixed: true,
        flip: true,
      }}
    >
      <Button
        icon={<span className={classes('material-icons-two-tone', f16i)}>menu</span>}
        baseline="background"
        ariaLabel="Toggle Menu"
        size="md"
      />
      {renderContent()}
    </Dropdown>
  )
}
