import { createElement, FC, useMemo } from 'react'
import { classes } from 'typestyle'
import { ComponentBaselineType, ComponentProps } from '../__core/component.types'
import { textClass } from './text.class'

export type TextComponentProps = {
  font?: 'default' | 'primary' | 'secondary'
  alt?: boolean
  baseline?: ComponentBaselineType
  children?: any
  className?: string
  as?: any
  semiBold?: boolean
  bold?: boolean
  italic?: boolean
  link?: boolean
  sublink?: boolean
  listLink?: boolean
  uppercase?: boolean
  preWrap?: boolean
  preLine?: boolean
  disableFlex?: boolean
  readable?: boolean
  crank?: boolean
  sub?: string
  secondary?: boolean
  disableLineHeight?: boolean
  light?: boolean
  lighter?: boolean
  shrink?: boolean
  disableMediaQuery?: boolean
  disableBreak?: boolean
  tag?: string
  disableWrap?: boolean
} & ComponentProps

const defaultProps: {
  baseline?: ComponentBaselineType
  as?: JSX.Element
} = {
  baseline: 'none',
}
export const createTextComponent = (textClass: any) => {
  const TextComponent: FC<TextComponentProps> = props => {
    const {
      alt,
      baseline,
      className,
      children,
      as,
      bold,
      italic,
      link,
      semiBold,
      uppercase,
      size,
      style,
      preWrap,
      preLine,
      sublink,
      readable,
      crank,
      sub,
      secondary,
      disableLineHeight,
      light,
      lighter,
      shrink,
      disableMediaQuery,
      disableBreak,
      font,
      listLink,
      tag,
      disableWrap,
    } = props

    const {
      base,
      baseThemed,
      baseSemiBold,
      baseBold,
      baseItalic,
      baseLink,
      baseListLink,
      baseUppercase,
      baseSized,
      basePreWrap,
      basePreLine,
      baseSublink,
      baseReadable,
      baseSecondary,
      baseNoLineHeight,
      baseLight,
      baseLighter,
      baseSublinkLighter,
      basePrimary,
      baseNoWrap,
    } = textClass.setProps({ alt, baseline, crank, sub, shrink, disableMediaQuery, disableBreak })

    let fontClass = ''

    if (font === 'primary') {
      fontClass = basePrimary
    } else if (font === 'secondary') {
      fontClass = baseSecondary
    }

    const componentProps = {
      className: classes(
        base,
        baseThemed,
        baseSized[size || 'sm'],
        semiBold && baseSemiBold,
        bold && baseBold,
        italic && baseItalic,
        link && baseLink,
        listLink && baseListLink,
        uppercase && baseUppercase,
        preWrap && basePreWrap,
        preLine && basePreLine,
        sublink && !lighter && baseSublink,
        readable && baseReadable,
        secondary && baseSecondary,
        disableLineHeight && baseNoLineHeight,
        light && baseLight,
        lighter && !sublink && baseLighter,
        sublink && lighter && baseSublinkLighter,
        disableWrap && baseNoWrap,
        fontClass,
        className,
      ),
      style,
      children,
      onClick: props.onClick,
    }

    const toReturn = useMemo(() => {
      return (
        <>
          {(() => {
            if (tag) {
              return createElement(tag, componentProps)
            }

            if (!as) {
              return <span {...componentProps} />
            }

            return createElement('span', { ...componentProps, ...as.props })
          })()}
        </>
      )
    }, [children, tag, className])

    return toReturn
  }

  TextComponent.defaultProps = defaultProps

  return TextComponent
}

const TextComponent: FC<TextComponentProps> = props => {
  const {
    alt,
    baseline,
    className,
    children,
    bold,
    italic,
    link,
    semiBold,
    uppercase,
    size,
    style,
    preWrap,
    preLine,
    sublink,
    readable,
    crank,
    sub,
    secondary,
    disableLineHeight,
    light,
    lighter,
    shrink,
    disableMediaQuery,
    disableBreak,
    font,
    listLink,
    disableWrap,
  } = props

  const {
    base,
    baseThemed,
    baseSemiBold,
    baseBold,
    baseItalic,
    baseLink,
    baseListLink,
    baseUppercase,
    baseSized,
    basePreWrap,
    basePreLine,
    baseSublink,
    baseReadable,
    baseSecondary,
    baseNoLineHeight,
    baseLight,
    baseLighter,
    baseSublinkLighter,
    basePrimary,
    baseNoWrap,
  } = textClass.setProps({
    alt,
    baseline,
    crank,
    sub,
    shrink,
    disableMediaQuery,
    disableBreak,
  })

  let fontClass: any = ''

  if (font === 'primary') {
    fontClass = basePrimary || ''
  } else if (font === 'secondary') {
    fontClass = baseSecondary
  }

  const getClassName = useMemo(() => {
    return classes(
      base,
      baseThemed,
      baseSized[size || 'sm'],
      semiBold && baseSemiBold,
      bold && baseBold,
      italic && baseItalic,
      listLink && baseListLink,
      link && baseLink,
      uppercase && baseUppercase,
      preWrap && basePreWrap,
      preLine && basePreLine,
      sublink && !lighter && baseSublink,
      readable && baseReadable,
      secondary && baseSecondary,
      disableLineHeight && baseNoLineHeight,
      light && baseLight,
      lighter && !sublink && baseLighter,
      sublink && lighter && baseSublinkLighter,
      disableWrap && baseNoWrap,
      fontClass,
      className,
    )
  }, [baseline, alt, semiBold, lighter])

  const componentProps = {
    className: getClassName,
    style,
    children,
    onClick: props.onClick,
  }

  // const toReturn = useMemo(() => {
  //   return (
  //     <>
  //       {(() => {
  //         return React.createElement(props.tag || 'span', componentProps)
  //       })()}
  //     </>
  //   )
  // }, [props.tag, props.children !== '-' && !props.children])

  return createElement(props.tag || 'span', componentProps)
}

export const Text = TextComponent
