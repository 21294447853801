import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { circleSizes } from './circle.sizes'
import { StyleSized } from '../style-class/style-class.types'
import { CircleProps } from '.'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for circle
   */
  base: string
  themed: string
  sized: StyleSized
}

type ClassProps = CircleProps

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline, border, borderContrast = 'medium', shadow, shadowContrast = 'medium' } = props
  const base = style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    boxShadow: shadow ? `1px 1px 10px 1px ${theme.boxes.shadow[shadowContrast]}` : '',
    border: border ? `1px solid ${theme.boxes.border[borderContrast]}` : '',
  })

  const themed = style({
    background: theme.getBaseline(baseline).color.medium,
  })

  const sized = {
    xs: style(circleSizes.xs),
    sm: style(circleSizes.sm),
    md: style(circleSizes.md),
    lg: style(circleSizes.lg),
    xl: style(circleSizes.xl),
  }

  return {
    base,
    themed,
    sized,
  }
}

/* Export ======================================================================================= */
export const circleClass = new StyleClass<ClassNames, ClassProps>(getNames)
