import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

export const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const history = useNavigate()
    const params = useParams()

    return <Component history={history} match={{ params }} {...props} />
  }
  return Wrapper
}
