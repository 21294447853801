import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  containerTitle: string
}

type ClassProps = any

const getNames: (props: any, theme: any) => ClassNames = (props, theme) => {
  const containerTitle = style({
    borderRadius: '4px 4px 0 0',
    borderBottom: `1px solid ${theme.boxes.border.dark}`,
  })

  return {
    containerTitle,
  }
}

/* Export ======================================================================================= */
export const modalClass = new StyleClass<ClassNames, ClassProps>(getNames)
