import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { createMediaQuery } from '../style-class/style-class.utils'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

type ClassNames = {
  /**
   * Base class for text field
   */
  base: string
  /**
   * Themed class for text field
   */
  themed: string

  /**
   * Proped class for text field
   */
  proped: {
    focused: string
  }

  option: string
  placeholder: string
}

type ClassProps = {
  baseline: ComponentBaselineType
  disabled?: boolean
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline, disabled } = props

  const base = style({
    background: theme.getBaseline(baseline).color.medium,
    border: 'none',
    position: 'relative',
    borderRadius: 4,
    cursor: !disabled ? 'pointer' : 'initial',
    padding: 0,
    width: '100%',
    // fontWeight: 500,
    $nest: {
      button: {
        color: 'inherit',
        background: 'none',
        // fontWeight: 500,
        border: 'none',
        outline: 'none',
        cursor: !disabled ? 'pointer' : 'initial',
      },
      ...createMediaQuery.sm({
        fontSize: '16px !important',
      }),
    },
  })

  const themed = style({
    background: theme.getBaseline(baseline).color.medium,

    borderRadius: 4,
  })

  const proped = {
    focused: style({
      $nest: {
        '*': {},
      },
    }),
  }

  const option = style({
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        background: theme.getBaseline(baseline).color.light,
      },
    },
  })

  const placeholder = style({
    whiteSpace: 'nowrap',
  })

  return {
    base,
    themed,
    proped,
    option,
    placeholder,
  }
}

/* Export ======================================================================================= */
export const selectClass = new StyleClass<ClassNames, ClassProps>(getNames)
