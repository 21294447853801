/**
 * TextFieldComponent
 *
 * Write CSS with following as reference
 *  <Field> // from withField()
 *    <FieldContainer className='aeki-tfc'>
 *      <input className='aeki-tfi'/>
 *    </FieldContainer>
 *  </Field>
 */
import { useRef, useEffect, FC } from 'react'

import * as React from 'react'
import { classes } from 'typestyle'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'
import { FormActions } from '../form/index'

/* Styles ======================================================================================= */
import { textFieldClass } from './text-field.class'
import { fieldClass } from '../field/field.class'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'
import { Box } from '../box/box.component'
import useBoolean from '../../hooks/use-boolean'

export type TextFieldProps = {
  type?: any
  baseline?: ComponentBaselineType
  freeSize?: boolean
  pattern?: string
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  disableAsyncValidation?: boolean
  lang?: string
} & FieldProps

/* <TextFieldComponent /> ======================================================================= */
/**  See bottom for wrapped component */
const TextFieldComponent: React.FC<FieldContextProps &
  TextFieldProps & {
    type: 'text' | 'password'
    freeSize?: boolean
    alignRight?: boolean
    autoFocus?: boolean
    pattern?: string
  }> = props => {
  const {
    className,
    baseline = 'background',
    dispatch,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    size = 'md',
    type = 'text',
    field = {
      focused: undefined,
      validate: [],
    },
    handleValidate,
    handleValue,
    alignRight,
    autoFocus,
    pattern,
    disableAsyncValidation,
    lang,
  } = props
  const inputRef = useRef(null)
  const [value, $value] = useValue(props.value || '')
  const [focused, $focused] = useValue(props.focused || false)
  const [touched, $touched] = useBoolean(false)

  useEffect(() => {
    if (dispatch) {
      dispatch({
        type: FormActions.FIELD,
        param: { name, value: { validate: props.validate, beforeSubmit: props.beforeSubmit } },
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (!value && !touched) {
      $value.set(props.formValue || '')
    }
  }, [props.formValue, value, touched])

  useEffect(() => {
    if (value) {
      $value.set(props.formValue || '')
    }
  }, [props.formValue])

  useEffect(() => {
    if (props.value !== undefined) {
      $value.set(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (props.value !== undefined) {
      handle('blur')()
    }
  }, [value])

  useEffect(() => {
    $focused.set(field.focused)
  }, [field.focused])

  useEffect(() => {
    if (field.focused || (inputRef as any).current === document.activeElement) {
      handle('focus')()
      ;(inputRef as any).current.focus()
    }

    if (field.focused === false) {
      handle('blur')()
    }
  }, [field.focused])

  useEffect(() => {
    if (props.focused) {
      ;(inputRef as any).current.focus()
    }
  }, [props.focused])

  useEffect(() => {
    if (props.autoFocus) {
      ;(inputRef as any).current.focus()
    }
  }, [autoFocus, inputRef])

  /* Field ClassNames --------------------------------------------------------------------------- */
  const { fieldSized } = fieldClass.setProps({
    size: props.size,
  })

  /* ClassNames --------------------------------------------------------------------------------- */
  const { base, themed } = textFieldClass.setProps({
    baseline: props.disabled ? 'disabled' : baseline,
    freeSize: props.freeSize,
    children: props.children,
    alignRight: props.alignRight,
  })
  /* Event Handlers ----------------------------------------------------------------------------- */
  const handle: (type: string) => any = type => {
    switch (type) {
      case 'keyDown':
        return async (e: React.KeyboardEvent<HTMLInputElement & HTMLTextAreaElement>) => {
          if (props.onKeyDown) {
            props.onKeyDown(e)
          }

          $touched.set(true)

          switch (e.keyCode) {
            case 13: {
              // e.preventDefault()
              if (onBlur) {
                onBlur()
              }

              if (dispatch && props.name) {
                dispatch({
                  type: FormActions.SET,
                  param: { name, value: await handleValidate(value, props.validate) },
                })
                dispatch({ type: FormActions.BLUR, param: { name } })
              } else {
                $focused.set(false)
              }

              break
            }
          }
        }
      case 'change':
        return async (e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
          const { value } = e.target

          if (onChange) {
            if (disableAsyncValidation) {
              onChange(value)
            } else {
              onChange(await handleValidate(value, props.validate))
            }
          }
          $value.set(value)
        }
      case 'focus':
        return () => {
          if (onFocus) {
            onFocus()
          }

          if (focused) {
            if (dispatch) {
              dispatch({ type: FormActions.FOCUS, param: { name } })
            } else {
              $focused.set(true)
            }
          }
        }

      case 'blur':
        return async () => {
          if (onBlur) {
            onBlur()
          }

          if (dispatch && props.name) {
            dispatch({
              type: FormActions.SET,
              param: { name, value: await handleValidate(value, props.validate) },
            })
            dispatch({ type: FormActions.BLUR, param: { name } })
          } else {
            $focused.set(false)
          }
        }
      default:
        throw new Error('Unexpected event handler')
    }
  }

  return (
    <Box
      className={classes('aeki-tfi', className, base, !props.freeSize && fieldSized[size], themed)}
      onClick={() => {
        ;(inputRef.current as any).focus()
      }}
      flex
      alignCenterY
      rounded
      // border
    >
      {!alignRight && props.children}
      <input
        autoComplete="new-password"
        onKeyDown={handle('keyDown')}
        onKeyUp={props.onKeyUp}
        onBlur={handle('blur')}
        onChange={handle('change')}
        onFocus={handle('focus')}
        placeholder={placeholder}
        ref={inputRef}
        type={type}
        value={handleValue(value)}
        disabled={props.disabled}
        pattern={pattern}
        lang={lang}
      />
      {alignRight && props.children}
    </Box>
  )
}

export const TextField: FC<TextFieldProps & {
  alignRight?: boolean
  autoFocus?: boolean
}> = withField(TextFieldComponent)
