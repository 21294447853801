export default {
  seo: {
    default: {
      mainImage: '',
      title: `${process.env.REACT_APP_PROJECT_NAME}`,
      description: '',
    },
  },
  whitelist: [],
  theme: [
    {
      baselines: {
        primary: {
          color: {
            light: '#e3f2fd',
            medium: '#4173ed',
            dark: '#4480f4',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        primaryAlt: {
          color: {
            light: 'transparent',
            medium: 'transparent',
            dark: 'transparent',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        secondary: {
          color: {
            light: 'rgb(29, 30, 43)',
            medium: 'rgb(29, 30, 43)',
            dark: 'rgba(29, 30, 43, 0.9)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        secondaryAlt: {
          color: {
            light: '#312c2c',
            medium: '#312c2c',
            dark: '#000000',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        background: {
          color: {
            light: 'rgb(248, 248, 248)',
            medium: 'rgb(244,244,244)',
            dark: 'rgb(240,240,240)',
          },
          contrast: {
            light: 'rgb(50, 49, 48)',
            medium: 'rgb(50, 49, 48)',
            dark: 'rgb(50, 49, 48)',
          },
        },
        backgroundAlt: {
          color: {
            light: 'rgb(95, 96, 127)',
            medium: 'rgb(95, 96, 127)',
            dark: 'rgb(95, 96, 127)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        none: {
          color: {
            light: 'none',
            medium: 'none',
            dark: 'none',
          },
          contrast: {
            light: 'inherit',
            medium: 'inherit',
            dark: 'inherit',
          },
        },
        surface: {
          color: {
            light: 'white',
            medium: 'white',
            dark: 'rgb(240,240,240)',
          },
          contrast: {
            light: 'rgb(25, 24, 23)',
            medium: 'rgb(25, 24, 23)',
            dark: 'rgb(25, 24, 23)',
          },
        },
        error: {
          color: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
          contrast: {
            light: 'red',
            medium: 'red',
            dark: 'red',
          },
        },
        errorAlt: {
          color: {
            light: 'rgb(217, 147, 47)',
            medium: 'rgb(217, 147, 47)',
            dark: 'rgb(217, 147, 47)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        disabled: {
          color: {
            light: 'rgb(230,230,230)',
            medium: 'rgb(230,230,230)',
            dark: 'rgb(230,230,230)',
          },
          contrast: {
            light: 'rgb(120,120,120)',
            medium: 'rgb(120,120,120)',
            dark: 'rgb(120,120,120)',
          },
        },
        disabledAlt: {
          color: {
            light: 'rgb(255, 255, 255)',
            medium: 'rgb(255, 255, 255)',
            dark: 'rgb(255, 255, 255)',
          },
          contrast: {
            light: 'rgb(210,210,210)',
            medium: 'rgb(210,210,210)',
            dark: 'rgb(210,210,210)',
          },
        },
      },
      boxes: {
        border: {
          light: 'rgb(240,240,240)',
          medium: 'rgb(234,234,234)',
          dark: 'rgb(228,228,228)',
        },
        shadow: {
          light: 'rgba(0,0,0,0.05)',
          medium: 'rgba(0,0,0,0.1)',
          dark: 'rgba(0,0,0,0.15)',
        },
      },
      layout: {
        navigation: {
          item: {
            base: {
              light: 'rgb(246,246,246)',
              medium: '#e3f2fd',
              dark: '#ebf7ff',
            },
            contrast: {
              light: '',
              medium: '',
              dark: '#769ab6',
            },
            circle: {
              light: '#e1dede',
              medium: '#e8e8e8',
              dark: '#c3d6e4',
            },
          },
        },
      },
      transition: {
        slow: '0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        medium: '0.35s cubic-bezier(0.19, 1, 0.22, 1)',
        fast: '0.1s cubic-bezier(0.19, 1, 0.22, 1)',
      },
    },
  ],
  fonts: {
    primary: 'Roboto',
    secondary: 'Roboto',
  },
  head: [],
  app: {
    headerProps: {
      menu: [],
      allowToggleEffect: false,
    },
    navbarProps: {
      padForLogo: false,
    },
    toolbarProps: {
      padForLogo: false,
    },
  },
  dictionary: {},
}
