
import { stylesheet, keyframes, classes } from 'typestyle'

/* Components =================================================================================== */
import { Box } from '../box/box.component'

const Variant = {
  text: 'text',
  circle: 'circle',
  rect: 'rect',
}

type SkeletonType = {
  variant?: keyof typeof Variant
  width?: number | string
  height?: number | string
  className?: string
}

const Skeleton: React.FC<SkeletonType> = ({ variant = Variant.rect, width, height, className }) => {
  const rendered = () => {
    switch (variant) {
      case Variant.circle:
        return (
          <Box
            className={classes(STYLES.container, (STYLES as any)[variant], className)}
            maxWidth={width}
            minWidth={width}
            minHeight={height}
            baseline="none"
          />
        )
      case Variant.rect:
        return (
          <Box
            className={classes(STYLES.container, (STYLES as any)[variant], className)}
            maxWidth={width}
            minWidth={width}
            minHeight={height}
            baseline="none"
          />
        )
      case Variant.text:
      default:
        return (
          <Box
            className={classes(STYLES.container, (STYLES as any)[variant], className)}
            maxWidth={width}
            minWidth={width}
            minHeight={height}
            baseline="none"
          />
        )
    }
  }
  return <>{rendered()}</>
}

export default Skeleton

const STYLES = stylesheet({
  container: {
    background: 'rgb(200,200,200)',
    borderRadius: 8,
    animationDuration: '0.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in',
    animationDirection: 'alternate',
    animation: keyframes({
      from: {
        opacity: 0.6,
      },
      to: {
        opacity: 0.4,
      },
    }),
  },
  rect: {
    borderRadius: '4px !important',
  },
  circle: {
    borderRadius: '50% !important',
  },
  text: {
    borderRadius: '8px !important',
  },
})
