/* Routes ======================================================================================= */
import Routes from './default/routes'

const Router = (props: any) => {
  const { state } = props

  return Routes({ ...state })
}

export default Router
