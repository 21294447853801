import { createContext, useEffect } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

/* Core ========================================================================================= */
import { setPreviousRoute, getCurrentUser, updateCurrentUser } from '../services/auth'
import { withRouter } from './with-router'

/* Hooks ======================================================================================== */
import useFetch from '../hooks/use-fetch'
import useBoolean from '../hooks/use-boolean'
import useValue from '../hooks/use-value'

/* Components =================================================================================== */
import PageNotFound from '../default/routes/page-not-found'
import { get } from '../share/find'

export const NavigationContext = createContext<any>({
  navigation: {},
})

const cached = { links: [] }
const NavigationWrapperComponent = (props: any) => {
  const { children, history } = props
  const { rowId, pageId } = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [, $loaded] = useBoolean(false)
  const [pageNotFound, $pageNotFound] = useBoolean(false)
  const [retrigger] = useValue()
  const [snippets, $snippets] = useValue()
  const [snippetPreset, $snippetPreset] = useValue()
  const [isSectionalSnippets, $isSectionalSnippets] = useBoolean(false)

  useEffect(() => {
    async function run() {
      await updateCurrentUser()
      const { currentUser } = getCurrentUser()

      if (currentUser) {
        // const socket = new WebSocket(`${process.env.REACT_APP_WS}/${currentUser.id}`)
        // socket.onopen = function(event) {
        //   // socket.send(JSON.stringify({ user: currentUser }))
        //   if (currentUser) {
        //     socket.send(
        //       JSON.stringify({
        //         type: 'user-connect',
        //         data: {
        //           user: currentUser,
        //           url: window.location.pathname,
        //         },
        //       }),
        //     )
        //   }
        // }
        // socket.onmessage = function(event) {
        //   if (event.data) {
        //     $retrigger.set(Date.now())
        //   }
        // }
      }
    }

    run()
  }, [])

  const [navigation, $navigation] = useFetch<{ links?: any[] }>(cached, {
    url: (() => {
      return `${process.env.REACT_APP_API_URL}${location.pathname.replace('/a/', '/n/')}`
    })(),
  })

  useEffect(() => {
    if (navigation.mounted) {
      if (location.pathname.includes('/a/')) {
        $navigation.get().then(function getNavitaion(payload: any) {
          cached.links = payload.links

          if (payload.error) {
            if (payload.code === 401) {
              setPreviousRoute()
              history('/a/signin')
            } else if (payload.code === 403) {
              $pageNotFound.set(true)
            }
          }

          let _snippets = get(
            rowId || pageId ? 'base.row.snippets' : 'base.table.snippets',
            payload,
            'array',
          )
          let _isSectionalSnippets = false

          if (_snippets.some((a: any) => a.type === 'Section')) {
            _snippets = _snippets.filter((a: any) => a.type === 'Section')
            _isSectionalSnippets = true
          }

          if (_isSectionalSnippets) {
            const parsedSnippets: any = {}

            _snippets.map((snippet: any, key: any) => {
              if (searchParams.get('section')) {
                $snippetPreset.set(searchParams.get('section'))
              } else if (key === 0) {
                $snippetPreset.set(snippet.name)
              }
              parsedSnippets[snippet.name] = snippet
            })

            $isSectionalSnippets.set(true)
            $snippets.set(parsedSnippets)
          } else {
            $snippets.set(_snippets)
          }

          $loaded.set(true)
        })
      } else {
        $loaded.set(true)
      }
    }
  }, [navigation.mounted])

  if (pageNotFound) {
    return <PageNotFound />
  }

  return (
    <NavigationContext.Provider
      value={{
        navigation,
        snippets,
        snippetPreset,
        $snippetPreset,
        retrigger,
        isSectionalSnippets,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default withRouter(NavigationWrapperComponent)
