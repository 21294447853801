import { color, important } from 'csx'
import { style } from 'typestyle'
import config from '../../../config'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { createMediaQuery } from '../style-class/style-class.utils'

/* Component===================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

/* Types ======================================================================================== */
export type ClassNames = {
  component: string
  /**
   * Base class for the most parent text element
   */
  base: string

  /**
   * Base class for secondary font family
   */
  baseSecondary: string

  /**
   * Sized extension class for text element
   */
  baseSized: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
  }

  /**
   * Themed extension class for text element
   */
  baseThemed: string

  /**
   * Themed extension class for semi-bolded text
   */
  baseSemiBold: string

  /**
   * Themed extension class for bolded text
   */
  baseBold: string

  /**
   * Themed extension class for italic text
   */
  baseItalic: string

  /**
   * Themed extension class for link text
   */
  baseLink: string

  /**
   * Themed extension class for sublink text
   */
  baseSublink: string

  /**
   * Themed extension class for larger spacing
   */
  baseReadable: string

  /**
   * Themed extension class for upper-cased text
   */
  baseUppercase: string

  basePreWrap: string
  basePreLine: string
  baseSub: string
  baseNoLineHeight: string
  baseLight: string
  baseLighter: string
  baseSublinkLighter?: string
  basePrimary?: string
  baseListLink: string
  baseNoWrap?: string
}

export type ClassProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
  crank?: boolean
  sub?: string
  shrink?: boolean
  disableMediaQuery?: boolean
  disableBreak?: boolean
}

export const BASE_SIZE = 15
export const TEXT_RATIO = 0.125
const sizes = {
  xl: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 2,
  lg: BASE_SIZE + BASE_SIZE * TEXT_RATIO,
  md: BASE_SIZE,
  sm: 14,
  xs: BASE_SIZE - BASE_SIZE * TEXT_RATIO * 2,
}

const ls: any = {
  xl: 0.4,
  lg: -0.2,
  md: 0,
  sm: 0.2,
  xs: 0,
}

export const createGetNames: (sizes: {
  xl: number
  lg: number
  md: number
  sm: number
  xs: number
}) => (props: ClassProps, theme: any) => ClassNames = sizes => (props, theme) => {
  const { baseline, alt, crank, sub, shrink, disableMediaQuery, disableBreak } = props

  const base = style({
    position: 'relative',
    wordBreak: disableBreak ? 'normal' : 'break-word',
    transition: '0.1s ease-in-out',
    transitionProperty: 'color',
  })

  const basePrimary = style({
    fontFamily: config.fonts.primary,
    fontFeatureSettings: '"ss01" 1, "ss05" 1',
  })

  const baseSecondary = style({
    fontFamily: config.fonts.secondary,
    fontFeatureSettings: '"ss05" 1',
  })

  const component = style({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  })

  let crankValue = 1

  if (crank) {
    crankValue = 1.5
  }

  if (shrink) {
    crankValue = 0.89
  }

  const createSub = (size: any) =>
    sub && {
      '&::after': {
        content: `"${sub}"`,
        position: 'absolute',
        top: '0%',
        fontSize: size * 0.3,
      },
    }

  const baseSized = {
    xl: style({
      // letterSpacing: ls.xl,
      fontSize: sizes.xl * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      ...createSub(sizes.xl * crankValue),
      $nest: !disableMediaQuery
        ? {
            ...createMediaQuery.md({
              ...createSub(sizes.xl * 0.95 * crankValue),
              fontSize: sizes.xl * 0.95 * crankValue,
            }),
            ...createMediaQuery.sm({
              ...createSub(sizes.xl * 0.9 * crankValue),
              fontSize: sizes.xl * 0.9 * crankValue,
            }),
            ...createMediaQuery.xs({
              ...createSub(sizes.xl * 0.85 * crankValue),
              fontSize: sizes.xl * 0.85 * crankValue,
            }),
          }
        : {},
    }),
    lg: style({
      // letterSpacing: ls.lg,
      fontSize: sizes.lg * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: !disableMediaQuery
        ? {
            ...createMediaQuery.md({
              fontSize: sizes.lg * 0.95 * crankValue,
            }),
            ...createMediaQuery.sm({
              fontSize: sizes.lg * 0.9 * crankValue,
            }),
            ...createMediaQuery.xs({
              fontSize: sizes.lg * 0.85 * crankValue,
            }),
          }
        : {},
    }),
    md: style({
      // letterSpacing: ls.md,
      fontSize: sizes.md * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: !disableMediaQuery
        ? {
            ...createMediaQuery.md({
              fontSize: sizes.md * 0.95 * crankValue,
            }),
            ...createMediaQuery.sm({
              fontSize: sizes.md * 0.9 * crankValue,
            }),
            ...createMediaQuery.xs({
              fontSize: sizes.md * 0.85 * crankValue,
            }),
          }
        : {},
    }),
    sm: style({
      // letterSpacing: ls.sm,
      fontSize: sizes.sm * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: !disableMediaQuery
        ? {
            ...createMediaQuery.md({
              fontSize: sizes.sm * 0.95 * crankValue,
            }),
            ...createMediaQuery.sm({
              fontSize: sizes.sm * 0.9 * crankValue,
            }),
            ...createMediaQuery.xs({
              fontSize: sizes.sm * 0.85 * crankValue,
            }),
          }
        : {},
    }),
    xs: style({
      // letterSpacing: ls.xs,
      fontSize: sizes.xs * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: !disableMediaQuery
        ? {
            ...createMediaQuery.md({
              fontSize: sizes.xs * 0.95 * crankValue,
            }),
            ...createMediaQuery.sm({
              fontSize: sizes.xs * 0.9 * crankValue,
            }),
            ...createMediaQuery.xs({
              fontSize: sizes.xs * 0.85 * crankValue,
            }),
          }
        : {},
    }),
  }

  const baseThemed = style({
    color: baseline ? theme.getBaseline(baseline, alt).contrast.medium : 'inherit',
  })

  const baseLight = style({
    color: color(baseline ? theme.getBaseline(baseline, alt).contrast.medium || 'black' : '')
      .lighten(0.2)
      .toRGB()
      .toString(),
  })

  const baseLighter = style({
    color: important(
      color(baseline ? theme.getBaseline(baseline, alt).contrast.medium || 'black' : '')
        .lighten(0.4)
        .toRGB()
        .toString(),
    ),
  })

  const baseSemiBold = style({
    fontWeight: important(500),
  })

  const baseBold = style({
    fontWeight: important(700),
  })

  const baseItalic = style({
    fontStyle: 'italic',
  })

  const baseLink = style({
    cursor: 'pointer',
    color: `${theme.getBaseline('primary').color.medium} !important`,
    $nest: {
      '*': {
        color: `${theme.getBaseline('primary').color.medium} !important`,
      },
      '&:hover': {
        color: '#6599da !important',
      },
    },
  })

  const baseListLink = style({
    cursor: 'pointer',
    color: 'rgb(34,34,34) !important',
    $nest: {
      '*': {
        color: 'rgb(34,34,34) !important',
      },
      '&:hover': {
        color: 'rgb(0,0,0) !important',
      },
    },
  })

  const baseSublink = style({
    cursor: 'pointer',
    color: 'rgb(120,120,120) !important',
    $nest: {
      '*': {
        color: '#6599da !important',
      },
      '&:hover': {
        color: '#3979ca !important',
      },
    },
  })

  const baseSublinkLighter = style({
    cursor: 'pointer',
    color: 'rgb(230,230,230) !important',
    $nest: {
      '*': {
        color: 'rgb(230,230,230) !important',
      },
      '&:hover': {
        color: '#3979ca !important',
      },
    },
  })

  const baseSub = style({
    cursor: 'pointer',
    color: 'rgb(120,120,120) !important',
  })

  const baseUppercase = style({
    textTransform: 'uppercase',
  })

  const basePreWrap = style({
    whiteSpace: 'pre-wrap',
  })

  const basePreLine = style({
    whiteSpace: 'pre-line',
  })

  const baseNoWrap = style({
    whiteSpace: 'nowrap',
  })

  const baseReadable = style({
    lineHeight: '1.7 !important',
  })

  const baseNoLineHeight = style({
    lineHeight: '0 !important',
  })

  return {
    component,
    base,
    basePrimary,
    baseSecondary,
    baseSized,
    baseThemed,
    baseSemiBold,
    baseBold,
    baseItalic,
    baseLink,
    baseListLink,
    baseUppercase,
    basePreWrap,
    basePreLine,
    baseSublink,
    baseReadable,
    baseSub,
    baseNoLineHeight,
    baseLight,
    baseLighter,
    baseSublinkLighter,
    baseNoWrap,
  }
}

export const textClass = new StyleClass<ClassNames, ClassProps>(createGetNames(sizes))
