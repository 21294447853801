export const BASE_SIZE = 13
export const TEXT_RATIO = 0.09

export const buttonSizes: any = {
  xs: {
    fontSize: 11,
    boxSize: 28,
    iconSize: 10,
    padding: '0 8px',
    paddingIconLeft: '0 8px 0 24px',
    paddingIconRight: '0 24px 0 8px',
    shrink: 0.95,
  },
  sm: {
    fontSize: BASE_SIZE - 1,
    boxSize: 30,
    iconSize: 14,
    padding: '0 10px',
    paddingIconLeft: '0 10px 0 27px',
    paddingIconRight: '0 27px 0 10px',
    shrink: 0.95,
  },
  md: {
    fontSize: BASE_SIZE,
    boxSize: 32,
    iconSize: 12,
    padding: '0 12px',
    paddingIconLeft: '0 12px 0 30px',
    paddingIconRight: '0 30px 0 12px',
    shrink: 0.95,
  },
  lg: {
    fontSize: BASE_SIZE + BASE_SIZE * TEXT_RATIO,
    boxSize: 40,
    iconSize: 18,
    padding: '0 16px',
    paddingIconLeft: '0 16px 0 36px',
    paddingIconRight: '0 36px 0 16px',
    shrink: 0.95,
  },
  xl: {
    fontSize: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 2,
    boxSize: 48,
    iconSize: 24,
    padding: '0 20px',
    paddingIconLeft: '0 24px 0 44px',
    paddingIconRight: '0 38px 0 24px',
    shrink: 0.95,
  },
  default: {
    fontSize: 12,
    boxSize: 24,
    iconSize: 14,
  },
}
