import { useRef, useLayoutEffect } from 'react'

import * as React from 'react'
import useValue from '../../hooks/use-value'
import Skeleton from '../skeleton'
import { Loader } from '../loader'

const Google: any = { script: undefined, loaded: false, object: null }

export const GoogleLoader: React.FC<any> = (props: {
  children: any
  component: any
  componentProps?: any
  loaderProps?: any
  className?: string
}) => {
  const { children } = props
  const [_google, $google] = useValue()
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY
  const mountedRef = useRef<boolean>(false)

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      const googleScript = document.getElementById('google-map')

      if (!googleScript) {
        const script = document.createElement('script')
        script.id = 'google-map'
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`

        document.head.append(script)

        script.onload = () => {
          resolve(window.google)
        }
      } else {
        if (window.google) {
          resolve(window.google)
        } else {
          googleScript.onload = () => {
            resolve(window.google)
          }
        }
      }
    })
  }

  useLayoutEffect(() => {
    mountedRef.current = true
    async function load() {
      const google = await loadScript()

      $google.set(google)
    }

    if (typeof window !== 'undefined') {
      load()
    }

    return () => {
      mountedRef.current = false
    }
  }, [$google])

  const Component = props.component
  /**
   * choosing either _google state or global Google.object insures to feed google api object with
   * unstable life cylce
   */

  return (
    <div className={props.className}>
      <Loader
        timeout={0}
        loaded={_google || Google.object}
        skeleton={<Skeleton {...props.loaderProps} />}
        component={<Component google={_google || Google.object} {...props.componentProps} />}
      />
    </div>
  )
}
