export const GLOBAL_CONTENTS = {
  contactUs: {
    en: 'Contact us',
    'ko-KR': '문의하기',
    'zh-CN': '联系我们',
  },
  signIn: {
    en: 'Sign In',
    'ko-KR': '로그인',
    'zh-CN': '登录',
  },
  signOut: {
    en: 'Sign Out',
    'ko-KR': '로그아웃',
    'zh-CN': '登出',
  },
  signUp: {
    en: 'Sign Up',
    'ko-KR': '회원가입',
    'zh-CN': '注册',
  },
  email: {
    en: 'Email',
    'ko-KR': '이메일',
    'zh-CN': '邮箱',
  },
  password: {
    en: 'Password',
    'ko-KR': '비밀번호',
    'zh-CN': '密码',
  },
  newPassword: {
    en: 'New Password',
    'ko-KR': '새로운 비밀번호',
    'zh-CN': '新密码',
  },
  or: {
    en: 'or',
    'ko-KR': '또는',
    'zh-CN': '或',
  },
  submit: {
    en: 'Submit',
    'ko-KR': '보내기',
    'zh-CN': '提交',
  },
  submitted: {
    en: 'Submitted!',
    'ko-KR': '보냈습니다!',
    'zh-CN': '已提交',
  },
  clickHere: {
    en: 'Click here',
    'ko-KR': '여기를 누르세요',
    'zh-CN': '点击',
  },
  alreadyHaveAccount: {
    en: 'Already have an account?',
    'ko-KR': '이미 회원가입을 하셨나요?',
    'zh-CN': '是否已经有账户？',
  },
  cannotBeEmpty: {
    en: 'Cannot be empty',
    'ko-KR': '비어 있을수 없습니다',
    'zh-CN': '不能留空',
  },
  goBackToLeftOff: {
    en: 'Go back to where your left off',
    'ko-KR': '이 전 페이지로 돌아가기',
    'zh-CN': '返回之前离开的时候',
  },
  close: {
    en: 'Close',
    'ko-KR': '닫기',
    'zh-CN': '关闭',
  },
  closeConfirm: {
    en: 'Close without saving?',
    'ko-KR': '저장하지 않고 닫으시겠어요?',
    'zh-CN': '关闭并不储存',
  },
  save: {
    en: 'Save',
    'ko-KR': '저장하기',
    'zh-CN': '储存',
  },
  saving: {
    en: 'Saving...',
    'ko-KR': '저장중...',
    'zh-CN': '正在储存',
  },
  saveAndAdd: {
    en: 'Save and add another',
    'ko-KR': '저장하고 또 생성하기',
    'zh-CN': '储存和再储存',
  },
  loading: {
    en: 'Loading',
    'ko-KR': '로딩중',
    'zh-CN': '正在缓冲',
  },
  support: {
    en: 'Support',
    'ko-KR': '고객지원',
    'zh-CN': '支援',
  },
  adminGroup: {
    en: 'Administrator',
    'ko-KR': '관리자 그룹',
    'zh-CN': '管理人',
  },
  page: {
    en: 'Page',
    'ko-KR': '페이지',
    'zh-CN': '业',
  },
  noData: {
    en: 'No Data',
    'ko-KR': '데이터가 없습니다',
    'zh-CN': '没数据',
  },
  sortBy: {
    en: 'Sort by',
    'ko-KR': '정렬하기',
    'zh-CN': '排序方式',
  },
  filters: {
    en: 'Filters',
    'ko-KR': '필터',
    'zh-CN': '筛选',
  },
  search: {
    en: 'Search',
    'ko-KR': '검색하기',
    'zh-CN': '搜索',
  },
  searchShort: {
    en: 'Search',
    'ko-KR': '검색하기',
    'zh-CN': '搜索',
  },
  searchDesc: {
    en: 'Type here to search',
    'ko-KR': '여기에 입력하세요',
    'zh-CN': '点击这里搜索',
  },
  reset: {
    en: 'Reset',
    'ko-KR': '초기화',
    'zh-CN': '重设',
  },
  portal: {
    en: 'Portal',
    'ko-KR': '포털',
    'zh-CN': '门户',
  },
  myBookmarks: {
    en: 'My Bookmarks',
    'ko-KR': '나의 북마크',
    'zh-CN': '我的收藏',
  },
  bookmarkAdd: {
    en: 'Add to my bookmarks',
    'ko-KR': '북마크에 저장하기',
    'zh-CN': '添加到我的收藏',
  },
  bookmarkRemove: {
    en: 'Remove from my bookmarks',
    'ko-KR': '북마크에서 삭제하기',
    'zh-CN': '移除我的收藏',
  },
  bookmarkAdded: {
    en: 'Added to my bookmarks',
    'ko-KR': '북마크에 저장되었습니다',
    'zh-CN': '已添加到我的收藏',
  },
  bookmarkRemoved: {
    en: 'Removed from my bookmarks',
    'ko-KR': '북마크에서 삭제되었습니다',
    'zh-CN': '已移除我的收藏',
  },
  noBookmarks: {
    en: "You don't have any bookmark(s)",
    'ko-KR': '북마크가 없습니다',
    'zh-CN': '你没有收藏',
  },
  remove: {
    en: 'Remove',
    'ko-KR': '삭제하기',
    'zh-CN': '清除',
  },
  edit: {
    en: 'Edit',
    'ko-KR': '수정',
    'zh-CN': '编辑',
  },
  resetPassword: {
    en: 'Reset Password',
    'ko-KR': '비밀번호 수정',
    'zh-CN': '重设密码',
  },
  changePassword: {
    en: 'Change Password',
    'ko-KR': '비밀번호 초기화',
    'zh-CN': '更换密码',
  },
  changePasswordConfirmation: {
    en: 'We will email a link to change the password. Shall we proceed?',
    'ko-KR': '비밀번호 초기화 링크를 유저의 이메일로 보내드립니다. 진행하시겠습니까?',
    'zh-CN': '重设密码邮件会发到您邮箱，是否确认？',
  },
  changePasswordConfirmationSelf: {
    en: 'We will email a link to change the password. Shall we proceed?',
    'ko-KR': '비밀번호 초기화 링크를 유저의 이메일로 보내드립니다. 진행하시겠습니까?',
    'zh-CN': '重设密码邮件会发到您邮箱，是否确认？',
  },
  editField: {
    en: 'Edit a Field',
    'ko-KR': '필드 수정하기',
    'zh-CN': '编辑',
  },
  insufficientPermission: {
    en: "You don't have sufficient permission. Contact your administrator.",
    'ko-KR': '권한이 부족합니다. 관리자에게 연락하새요.',
    'zh-CN': '您没有权限，请咨询您的管理人',
  },
  updatedSuccessfully: {
    en: 'Successfully updated!',
    'ko-KR': '성공적으로 업데이트가 되었습니다!',
    'zh-CN': '已更新！',
  },
  myGroups: {
    en: 'My Group(s)',
    'ko-KR': '내 그룹',
    'zh-CN': '我的组',
  },
  myProfile: {
    en: 'My Profile',
    'ko-KR': '내 프로필',
    'zh-CN': '我的主页',
  },
  groupName: {
    en: 'Group Name',
    'ko-KR': '그룹 이름',
    'zh-CN': '组名',
  },
  noGroups: {
    en: "You don't have any group(s)",
    'ko-KR': '소속된 그룹이 없습니다',
    'zh-CN': '您没有组',
  },
  addFormPrefix: {
    en: 'Create ',
    'ko-KR': '',
    'zh-CN': '创建',
  },
  addFormPostfix: {
    en: '',
    'ko-KR': ' 생성하기',
    'zh-CN': '',
  },
  editFormPrefix: {
    en: 'Edit',
    'ko-KR': '',
    'zh-CN': '编辑',
  },
  editFormPostfix: {
    en: '',
    'ko-KR': ' 수정하기',
    'zh-CN': '',
  },
  name: {
    en: 'Name',
    'ko-KR': '이름',
    'zh-CN': '姓名',
  },
  phone: {
    en: 'Phone Number',
    'ko-KR': '전화번호',
    'zh-CN': '电话号码',
  },
  position: {
    en: 'Position',
    'ko-KR': '직책',
    'zh-CN': '位置',
  },
  type: {
    en: 'Type',
    'ko-KR': '타입',
    'zh-CN': '类别',
  },
  tableEmptyPrefix: {
    en: 'No ',
    'ko-KR': '',
    'zh-CN': '否',
  },
  tableEmptyPostfix: {
    en: ' were found.',
    'ko-KR': '을(를) 찾을수 없습니다.',
    'zh-CN': '搜索到',
  },
  chooseFiles: {
    en: 'Choose file(s)',
    'ko-KR': '파일(들)을 선택하세요',
    'zh-CN': '请选择文件',
  },
  noActivities: {
    en: 'There is no activities.',
    'ko-KR': '활동내역이 없습니다.',
    'zh-CN': '没有活动',
  },
  closeMenu: {
    en: 'Close Menu',
    'ko-KR': '메뉴 닫기',
    'zh-CN': '关闭菜单',
  },
  openMenu: {
    en: 'Close Menu',
    'ko-KR': '메뉴 열기',
    'zh-CN': '关闭菜单',
  },
  closeHeader: {
    en: 'Close Header',
    'ko-KR': '헤더 닫기',
    'zh-CN': '关闭头段',
  },
  openHeader: {
    en: 'Open Header',
    'ko-KR': '헤더 열기',
    'zh-CN': '开启头段',
  },
  openPortal: {
    en: 'Open Portal',
    'ko-KR': '포털 열기',
    'zh-CN': '开启户门',
  },
  confirm: {
    en: 'Confirm',
    'ko-KR': '확인',
    'zh-CN': '确认',
  },
  confirming: {
    en: 'Confirm...',
    'ko-KR': '확인중...',
    'zh-CN': '确认中',
  },
  prev: {
    en: 'Prev',
    'ko-KR': '뒤로',
    'zh-CN': '上一个',
  },
  next: {
    en: 'Next',
    'ko-KR': '다음',
    'zh-CN': '下一个',
  },
  asyncSearchResults: {
    en: 'Search Result(s)',
    'ko-KR': '검색 결과',
    'zh-CN': '搜索结果',
  },
  asyncSearchHelpText: {
    en: 'Search for an entry by typing the name and click on the option to set value.',
    'ko-KR': '붙여넣을 줄을 검색하고 클릭하세요',
    'zh-CN': '用名字去搜索',
  },
  asyncSearchRecent: {
    en: 'Recently selected',
    'ko-KR': '최근에 불려진 줄(들)',
    'zh-CN': '刚刚已选',
  },
  asyncNone: {
    en: 'None',
    'ko-KR': '검색결과가 없습니다',
    'zh-CN': '没有',
  },
  asyncClickToAdd: {
    en: 'Click here to add',
    'ko-KR': '줄 생성하기',
    'zh-CN': '点击添加',
  },
  read: {
    en: 'Read',
    'ko-KR': '읽기',
    'zh-CN': '已读',
  },
  write: {
    en: 'Write',
    'ko-KR': '쓰기',
    'zh-CN': '写',
  },
  update: {
    en: 'Update',
    'ko-KR': '수정',
    'zh-CN': '更新',
  },
  delete: {
    en: 'Delete',
    'ko-KR': '삭제',
    'zh-CN': '删除',
  },
  accessAll: {
    en: 'Access All',
    'ko-KR': '전체 액세스',
    'zh-CN': '探访所有',
  },
  done: {
    en: 'Done!',
    'ko-KR': '완료!',
    'zh-CN': '已完成！',
  },
  haveAccount: {
    en: 'Already have an acccount?',
    'ko-KR': '이미 가입하셨나요?',
    'zh-CN': '已经有账户？',
  },
  thankSignUp: {
    en: 'Thank you for signing up!',
    'ko-KR': '가입해주셔서 감사합니다!',
    'zh-CN': '感谢申请！',
  },
  afterSignUp: {
    en: 'Please wait while we process your registration.',
    'ko-KR': '새로운 개정을 생성중입니다. 잠시만 기달려주세요.',
    'zh-CN': '正在注册，请稍等',
  },
  cancel: {
    en: 'Cancel',
    'ko-KR': '취소',
    'zh-CN': '取消',
  },
  accept: {
    en: 'Accept',
    'ko-KR': '동의',
    'zh-CN': '已录取',
  },
  helpTextTab: {
    en: 'Shift + scroll or swipe to nagivate',
    'ko-KR': '쉬프트 + 스크롤 또는 밀어서 열람하세요',
    'zh-CN': '按Shift+滚动去移动',
  },
  helpTextTable: {
    en: 'Shift + scroll or swipe to browse the table',
    'ko-KR': '쉬프트 + 스크롤 또는 밀어서 열람하세요',
    'zh-CN': '按Shift+滚动去查看',
  },
  placeholderText: {
    en: 'Enter text here',
    'ko-KR': '여기에 내용을 적으세요',
    'zh-CN': '请输入文字',
  },
  placeholderMessage: {
    en: 'Type your message here',
    'ko-KR': '여기에 내용을 적으세요',
    'zh-CN': '请输入信息',
  },
  noMatchMessage: {
    en: 'No results found',
    'ko-KR': '결과가 없습니다',
    'zh-CN': '没有搜索内容',
  },
  searchMessage: {
    en: 'Type to search',
    'ko-KR': '검색어를 입력하세요',
    'zh-CN': '请输入去搜索',
  },
  moreActions: {
    en: 'See more actions',
    'ko-KR': '더 보기',
    'zh-CN': '看多一点',
  },
  signInDesc: {
    en: `to continue to ${process.env.REACT_APP_PROJECT_NAME}`,
    'ko-KR': `${process.env.REACT_APP_PROJECT_NAME}을 사용하기위해 로그인 해주세요`,
    'zh-CN': `${process.env.REACT_APP_PROJECT_NAME}`,
  },
  forgetPassword: {
    en: 'Forgot your password? ',
    'ko-KR': '비밀번호를 잊어버리셨나요? ',
    'zh-CN': '忘记密码？',
  },
  notRegistered: {
    en: 'Not registered? Sign up here!',
    'ko-KR': '회원이 아니신가요? 여기서 가입하세요!',
    'zh-CN': '还没注册？立刻注册！',
  },
  signInWithMicrosoft: {
    en: 'Sign in with Microsoft',
    'ko-KR': '마이크로소프트로 로그인 하기',
    'zh-CN': '通过微软账号登录',
  },
  signInWithGoogle: {
    en: 'Sign in with Google',
    'ko-KR': '구글로 로그인 하기',
    'zh-CN': '通过谷歌账号登录',
  },
  overview: {
    en: 'Overview',
    'ko-KR': '현황',
    'zh-CN': '概述',
  },
  activities: {
    en: 'Activities',
    'ko-KR': '활동내역',
    'zh-CN': '活动',
  },
  typeNewPassword: {
    en: 'Type your new password',
    'ko-KR': '새로운 비밀번호를 기입하세요',
    'zh-CN': '请输入您的新密码',
  },
  resetYourPassword: {
    en: 'Reset your password',
    'ko-KR': '비밀번호를 리셋하세요',
    'zh-CN': '重设您的密码',
  },
  resetPasswordSubtitle: {
    en: "You'll recieve password reset link.",
    'ko-KR': '이메일 기입하고 보내시면 링크를 보내드립니다',
    'zh-CN': '您将会收到重设密码的链接',
  },
  emailNotRegistered: {
    en: 'Email is not registered or registered through other login services.',
    'ko-KR': '기입하신 이메일은 가입이 되어있지 않습니다.',
    'zh-CN': '邮箱并没有注册',
  },
  emailConfirmation: {
    en: 'We sent you an email',
    'ko-KR': '링크를 이메일로 보내드렸습니다',
    'zh-CN': '邮件已发到您邮箱',
  },
  emailConfirmationSubtitle: {
    en: 'with a password reset link.',
    'ko-KR': '본인 이메일 보관함과 스팸을 확인해주세요.',
    'zh-CN': '包括重设密码的链接',
  },
  userConfirmationTitle: {
    en: 'Confirmation',
    'ko-KR': '확인하기',
    'zh-CN': '确认码',
  },
  userConfirmationSubtitle: {
    en: "You'll recieve password reset link.",
    'ko-KR': '이메일 기입하고 보내시면 링크를 보내드립니다',
    'zh-CN': '您将会收到一个重设密码的链接',
  },
  userConfirmationEmailNotRegistered: {
    en: 'Email is not registered or registered through other login services.',
    'ko-KR': '기입하신 이메일은 가입이 되어있지 않습니다.',
    'zh-CN': '邮箱并没有注册',
  },
  userConfirmationModalTitle: {
    en: 'We sent you an email',
    'ko-KR': '링크를 이메일로 보내드렸습니다',
    'zh-CN': '邮件已发到您邮箱',
  },
  userConfirmationModalSubtitle: {
    en: 'with a password reset link.',
    'ko-KR': '본인 이메일 보관함과 스팸을 확인해주세요.',
    'zh-CN': '包括重设密码的链接',
  },
  signInWait: {
    en: 'Please wait while we process your sign in',
    'ko-KR': '로그인 진행중입니다. 잠시만 기달려주세요.',
    'zh-CN': '正在登录，请稍等',
  },
  groupInformation: {
    en: 'Group Information',
    'ko-KR': '그룹 정보',
    'zh-CN': '组队信息',
  },
  inviteTeammate: {
    en: 'Invite a Teammate',
    'ko-KR': '팀원 초대하기',
    'zh-CN': '邀请队友',
  },
  editGroupProfile: {
    en: 'Edit Group Profile',
    'ko-KR': '그룹 프로필 수정하기',
    'zh-CN': '编辑组队页面',
  },
  editMyProfile: {
    en: 'Edit My Profile',
    'ko-KR': '내 프로필 수정',
    'zh-CN': '编辑我的页面',
  },
  greetings: {
    en: 'Hi,',
    'ko-KR': '안녕하세요,',
    'zh-CN': '您好，',
  },
  namePostFix: {
    en: '',
    'ko-KR': '님',
    'zh-CN': '',
  },
  send: {
    en: 'Send',
    'ko-KR': '입력',
    'zh-CN': '',
  },
  editComment: {
    en: 'Edit a comment',
    'ko-KR': '댓글 수정하기',
    'zh-CN': '编辑',
  },
  body: {
    en: 'Body',
    'ko-KR': '내용',
    'zh-CN': '主体',
  },
  edited: {
    en: 'Edited',
    'ko-KR': '수정됨',
    'zh-CN': 'Edited',
  },
  deleted: {
    en: 'Deleted',
    'ko-KR': '삭제됨',
    'zh-CN': 'Deleted',
  },
  deleting: {
    en: 'Deleting...',
    'ko-KR': '삭제중...',
    'zh-CN': 'Deleting...',
  },
}
