import { useRef, useEffect } from 'react'

import * as React from 'react'
import { classes, style, stylesheet } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'
import useCounter from '../../hooks/use-counter'
import useArray from '../../hooks/use-array'
import useValue from '../../hooks/use-value'

/* Styles ======================================================================================= */
import { Text } from '../text/text.component'
import { Box } from '../box/box.component'
import { paddingVerticalOuter } from '../../styles/default/padding'
import { pb12 } from '../../styles/styleset/padding/pb12'
import { pt12 } from '../../styles/styleset/padding/pt12'
import { pt4 } from '../../styles/styleset/padding/pt4'
import { pb4 } from '../../styles/styleset/padding/pb4'
import { pt8 } from '../../styles/styleset/padding/pt8'

/* <GooglePlaceWrapper /> ======================================================================= */
export const GooglePlaceWrapper: React.FC<{
  children: any
  center: any
  $center: any
  searchText?: string
  onTextChange?: (text: any) => {}
  onSearchTextChange?: (text?: any, center?: any) => void
  GoogleAPI?: any
  google?: any
  onSubmit?: any
  additionalTab?: any
  noWrap?: boolean
  maxWidth?: number
  footer?: any
  maxRow?: number
}> = ({
  children,
  $center,
  onSearchTextChange,
  searchText,
  onSubmit,
  additionalTab,
  onTextChange,
  noWrap,
  maxWidth,
  footer,
  maxRow,
}) => {
  const [focused, $focused] = useBoolean(false)
  const [noResult, $noResult] = useBoolean(false)
  const [loaded, $loaded] = useBoolean(true)
  const [selected, $selected] = useCounter(-1, { min: 0, max: 0 })
  const parentRef = useRef(null)
  const [autoComplete, $autoComplete] = useArray<any>()
  const [search, $search] = useValue({})

  let displaySuggestions: any
  let service: any

  displaySuggestions = function(
    predictions: google.maps.places.QueryAutocompletePrediction[],
    status: google.maps.places.PlacesServiceStatus,
  ) {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      $noResult.set(true)
      return
    }

    if (maxRow) {
      const _predictions = [...predictions]
      _predictions.splice(maxRow, _predictions.length)

      $autoComplete.set(_predictions)
      $selected.set(0)
      $selected.setMax(maxRow)
    } else {
      $autoComplete.set(predictions)
      $selected.set(0)
      $selected.setMax(5)
    }
  }

  service = new google.maps.places.AutocompleteService()

  useEffect(() => {
    if (google) {
      $loaded.set(true)
    }
  }, [google])

  useEffect(() => {
    if (google && $center) {
      if (Object.keys(search).length > 0) {
        const request = {
          query: search.description,
          fields: ['geometry', 'place_id'],
        }

        // google place api needs a node to process so feed in empty div
        const temp = document.createElement('div')
        const service2 = new google.maps.places.PlacesService(temp)

        service2.findPlaceFromQuery(request, function(results: any, status: any) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            $center.set({
              lng: results[0].geometry.location.lng(),
              lat: results[0].geometry.location.lat(),
            })

            if (onSearchTextChange)
              onSearchTextChange(search.description, {
                lng: results[0].geometry.location.lng(),
                lat: results[0].geometry.location.lat(),
              })
          }
        })
      } else if (searchText) {
        const request = {
          query: searchText,
          fields: ['geometry', 'place_id'],
        }

        const temp = document.createElement('div')
        const service2 = new google.maps.places.PlacesService(temp)

        service2.findPlaceFromQuery(request, function(results: any, status: any) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            $center.set({
              lng: results[0].geometry.location.lng(),
              lat: results[0].geometry.location.lat(),
            })

            // const request = {
            //   placeId: results[0].place_id,
            //   fields: ['address_components'],
            // }

            // service2.getDetails(request, function(results2: any, status: any) {
            //   if (status === google.maps.places.PlacesServiceStatus.OK) {
            //   }
            // })
          }
        })
      }
    }
  }, [search, searchText, google])

  const close = () => {
    $focused.set(false)
  }

  const handleItemClick = (key: any) => () => {
    $search.set(autoComplete[key])
    $focused.set(false)
    $selected.reset()

    if (onSubmit) {
      onSubmit(autoComplete[key].description)
    }
  }

  let debounceId: any

  const handleFocus = () => {
    $focused.set(true)
  }
  const handleBlur = () => {
    // if (autoComplete[0]) {
    //   if (onSearchTextChange) {
    //     onSearchTextChange(autoComplete[0].description)
    //   }
    // }
  }

  const handleKeyDown = (e: any) => {
    $focused.set(true)
    $noResult.set(false)
    if (e.keyCode === 13) {
      if (autoComplete[selected as number] && selected > -1) {
        $search.set(autoComplete[selected as number])

        $selected.reset()
        $focused.reset()
        if (onSubmit) {
          onSubmit(autoComplete[selected as number].description)
        }
      }
    } else if (e.keyCode === 40) {
      $selected.increment()
    } else if (e.keyCode === 38) {
      $selected.decrement()
    }
  }

  const handleChange = (value: any) => {
    if (service && value) {
      onTextChange?.(value)
      clearTimeout(debounceId)
      debounceId = setTimeout(() => {
        service.getPlacePredictions(
          {
            input: value,
            componentRestrictions: { country: 'ca' },
          },
          displaySuggestions,
        )
      }, 400)
    }
  }

  return (
    <Box relative fullWidth baseline="none" maxWidth={maxWidth} centerAutoX>
      {/* <OutsideClickHandler onOutsideClick={close}> */}
      {loaded &&
        children?.({
          onFocus: handleFocus,
          onBlur: handleBlur,
          onKeyDown: handleKeyDown,
          onChange: handleChange,
          value: Object.keys(search).length > 0 ? search.description : searchText,
        })}
      <Box className={STYLES.containerDialog} ref={parentRef}>
        <Box
          className={style({
            maxHeight: '60vh',
            overflow: 'auto',
          })}
        >
          {autoComplete.length > 0 && (
            <Box className={classes(STYLES.containerDialogContent, paddingVerticalOuter)}>
              <Box className={pt8} flex>
                <Box
                  className={classes(pb4, pt4)}
                  maxWidth={maxWidth}
                  centerAutoX
                  fullWidth
                  alignCenterY
                >
                  <Text size="sm" baseline="surface" semiBold alt uppercase shrink lighter>
                    Locations
                  </Text>
                </Box>
              </Box>
              {autoComplete.map((item: any, key: any) => {
                return (
                  <Box
                    className={classes(pt12, pb12, STYLES.containerItem)}
                    onClick={handleItemClick(key)}
                    // baseline={selected === key ? 'background' : 'none'}
                    key={key}
                  >
                    <Box baseline="none" maxWidth={maxWidth} centerAutoX>
                      <Text size="md">{item.description}</Text>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          )}
          {additionalTab}
        </Box>
        {footer}
      </Box>
    </Box>
  )
}

const STYLES = stylesheet({
  containerDialog: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  containerDialogContent: {
    overflow: 'hidden',
  },
  containerItem: {
    $nest: {
      '&:hover': {
        opacity: 0.6,
        cursor: 'pointer',
      },
    },
  },
})
