import { classes, style, keyframes, stylesheet } from 'typestyle'
import { ComponentSizeType } from '../__core/component.types'

export const Loading = (props: { size?: ComponentSizeType }) => {
  const { size = 'lg' } = props

  return (
    <i className={classes('material-icons-two-tone', STYLES.icon, SIZES[size])}>hourglass_empty</i>
  )
}

const SIZES = {
  xs: style({ fontSize: '14px !important' }),
  sm: style({ fontSize: '18px !important' }),
  md: style({ fontSize: '20px !important' }),
  lg: style({ fontSize: '26px !important' }),
  xl: style({ fontSize: '32px !important' }),
}

const STYLES = stylesheet({
  icon: {
    background: 'none',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
    animationName: keyframes({
      '100%': {
        transform: 'rotate(360deg)',
      },
    }),
  },
})
