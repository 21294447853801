import * as React from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { circleClass } from './circle.class'

/* Types ======================================================================================== */
import { ComponentSizeType, ComponentBaselineType } from '../__core/component.types'
export type CircleProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  size?: ComponentSizeType
  onClick?: any
  baseline?: ComponentBaselineType
  border?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
  shadow?: boolean
  shadowContrast?: 'light' | 'medium' | 'dark'
}

/* <Circle /> =================================================================================== */
export const Circle: React.FC<CircleProps> = props => {
  const { size = 'md', children, className, style, onClick } = props
  const { base, themed, sized } = circleClass.setProps(props)

  return (
    <div className={classes(base, themed, sized[size], className)} style={style} onClick={onClick}>
      {children}
    </div>
  )
}
