/**
 * Copyright 2022 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useEffect, useCallback } from 'react'
import { classes } from 'typestyle'
import { Link } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useValue from '../../../__aeki__/hooks/use-value'

/* Services ===================================================================================== */
import { getCurrentUser, clearCurrentUser, setPreviousRoute } from '../../../__aeki__/services/auth'

/* Components =================================================================================== */
import Skeleton from '../../../__aeki__/components/skeleton'
import { Box } from '../../../__aeki__/components/box/box.component'
import { Button } from '../../../__aeki__/components/button'
import { Dropdown } from '../../../__aeki__/components/dropdown'
import { Loader } from '../../../__aeki__/components/loader'
import { Circle } from '../../../__aeki__/components/circle'
import { Text } from '../../../__aeki__/components/text/text.component'

/* Styles ======================================================================================= */
import { br42i } from '../../../__aeki__/styles/styleset/border-radius/br42i'
import { mb4 } from '../../../__aeki__/styles/styleset/margin/mb4'
import { w100 } from '../../../__aeki__/styles/styleset/width/w100'
import { f16i } from '../../../__aeki__/styles/styleset/font-size/f16i'
import { p8 } from '../../../__aeki__/styles/styleset/padding/p8'
import { f18i } from '../../../__aeki__/styles/styleset/font-size/f18i'
import { ml4 } from '../../../__aeki__/styles/styleset/margin/ml4'
import { primaryContrastMediumi } from '../../../__aeki__/styles/styleset/primary-contrast-medium/primaryContrastMediumi'
import { A } from '../../../__aeki__/components/a'
import { mr8 } from '../../../__aeki__/styles/styleset/margin/mr8'
import { hidesmi } from '../../../__aeki__/styles/styleset/hide/hidesmi'

/* <UserMenu /> ================================================================================= */
export const UserMenu = ({ toggled }: { toggled?: boolean }) => {
  const [currentUser, $currentUser] = useValue()

  useEffect(() => {
    const { currentUser = {} } = getCurrentUser()
    $currentUser.set(currentUser)
  }, [])

  const handleSignOut = (close: any) => () => {
    close()

    $currentUser.set(clearCurrentUser())
    const url = window.location.href.replace('auth=1&', '').replace('auth=1', '')
    window.location.replace(url)
  }

  const renderContent = useCallback(() => {
    if (currentUser && Object.keys(currentUser).length > 0 && !currentUser.notLoaded) {
      return (
        <>
          {currentUser?.groups?.length > 0 && (
            <Button
              as={
                <A
                  to={
                    currentUser?.groups?.length === 1
                      ? `/a/groups/${currentUser?.groups[0]}`
                      : '/a/groups'
                  }
                  title={'Go to my groups'}
                />
              }
              className={classes(mr8, hidesmi)}
              icon={<span className={classes('material-icons-two-tone', f16i)}>people</span>}
              label={'My Group(s)'}
              size="md"
              baseline={'background'}
              square
            />
          )}
          <Dropdown
            floatProps={{
              anchorPosition: 'bottom right',
              dialogPosition: 'top right',
              width: 160,
              fixed: true,
              flip: true,
            }}
          >
            <Box baseline="none">
              <Button
                style={{
                  paddingRight: 0,
                }}
                label={
                  <span
                    className={classes('material-icons-two-tone', f16i, ml4)}
                    style={{ color: toggled ? '#39374a' : 'white' }}
                  >
                    keyboard_arrow_down
                  </span>
                }
                ariaLabel="Toggle user menu"
                baseline="none"
                size="md"
                icon={
                  <Circle baseline="primary" size="sm" style={{ width: 32, height: 32 }}>
                    <Text size="xs" className={primaryContrastMediumi}>
                      {currentUser.firstName[0]}
                      {currentUser.lastName[0]}
                    </Text>
                  </Circle>
                }
              />
            </Box>
            {(close: any) => {
              return (
                <Box className={p8} baseline="surface" minWidth={140} rounded shadow flex row>
                  <Button
                    as={<Link to="/a/user/page/user-profile" />}
                    className={classes(mb4, w100)}
                    label="My Profile"
                    size="sm"
                    baseline="background"
                    icon={
                      <span className={classes('material-icons-two-tone', f16i)}>account_box</span>
                    }
                    square
                  />
                  {currentUser.groups && currentUser?.groups?.length > 0 ? (
                    <Button
                      as={
                        <Link
                          to={
                            currentUser.groups.length === 1
                              ? `/a/groups/${currentUser.groups[0]}`
                              : '/a/groups'
                          }
                        />
                      }
                      className={classes(mb4, w100)}
                      baseline="background"
                      label="My Group(s)"
                      icon={<span className={classes('material-icons-two-tone', f16i)}>group</span>}
                      size="sm"
                      square
                    />
                  ) : (
                    ''
                  )}
                  <Button
                    label={'Sign Out'}
                    size="sm"
                    baseline="secondary"
                    onClick={handleSignOut(close)}
                    square
                  />
                </Box>
              )
            }}
          </Dropdown>
        </>
      )
    }
    return (
      <>
        <Button
          as={<Link to="/a/signin" title="Go sign in" />}
          onClick={() => {
            setPreviousRoute()
          }}
          label="Sign in"
          size="md"
          baseline="primary"
          icon={<span className={classes('material-icons-two-tone', f18i)}>account_circle</span>}
        />
      </>
    )
  }, [currentUser, toggled])

  return (
    <Loader
      timeout={1}
      loaded={true}
      skeleton={<Skeleton width={85} height={32} variant="circle" className={br42i} />}
      component={renderContent()}
    />
  )
}
