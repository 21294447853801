import { useEffect, useRef } from 'react'

import * as React from 'react'
import { classes } from 'typestyle'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { Box } from '../box/box.component'

/* Types ======================================================================================== */
import { ComponentSizeType } from '../__core/component.types'

/* Styles ======================================================================================= */
import { searchClass } from './search.class'
import { ml8 } from '../../styles/styleset/margin/ml8'
import { ml4i } from '../../styles/styleset/margin/ml4i'
import { pl8 } from '../../styles/styleset/padding/pl8'
import { f20i } from '../../styles/styleset/font-size/f20i'

type SearchProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  placeholder?: string
  size?: ComponentSizeType
  onFocus?: any
  onBlur?: any
  onChange?: any
  onKeyDown?: any
  ref?: any
  value?: any
  autoFocus?: boolean
  border?: boolean
  disableIconMargin?: boolean
}

/* <Search /> ================================================================================= */
const SearchComponent: React.FC<SearchProps> = props => {
  const {
    className,
    style,
    placeholder = 'Type here to search',
    onFocus,
    onChange,
    onBlur,
    onKeyDown,
    ref,
    value,
    autoFocus,
    disableIconMargin,
  } = props
  const [text, $text] = useValue(value)
  const [focused, $focused] = useBoolean(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (onChange) onChange(text)
  }, [text])

  useEffect(() => {
    if (value) {
      $text.set(value)
    }
  }, [value])

  useEffect(() => {
    if (focused) {
      if (onFocus) onFocus()
    } else {
      if (onBlur) onBlur()
    }
  }, [focused])

  useEffect(() => {
    if (autoFocus) {
      $focused.set(true)
      setTimeout(() => {
        ;(inputRef as any).current.focus()
      }, 100)
    }
  }, [])

  const handleChange = (event: any) => $text.set(event.target.value)
  const handleFocus = () => $focused.set(true)
  const handleBlur = () => $focused.set(false)

  const { base, themed, proped, input, inputSized } = searchClass.setProps({})

  return (
    <Box
      ref={ref}
      baseline="none"
      className={classes('aeki-search', base, themed, focused && proped.focused, className)}
      style={style}
      rounded
      flex
      spaceBetween
    >
      <span className={classes('material-icons-two-tone', !disableIconMargin && ml8, f20i)}>
        search
      </span>
      <Box baseline="none" className={pl8} flex alignCenterY fullWidth>
        <input
          ref={inputRef}
          className={classes(input, inputSized['xl'], ml4i)}
          placeholder={placeholder}
          value={text}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
        />
      </Box>
      {props.children}
    </Box>
  )
}

/* Export ======================================================================================= */
export const Search = SearchComponent
