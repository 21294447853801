/* DEFAULT_CONFIG =============================================================================== */
export const DEFAULT_CONFIG = {
  topbar: {
    active: false,
  },
  navbar: {
    active: false,
  },
  header: {
    active: true,
    // visible: true,
  },
  menubar: {
    active: true,
  },
  toolbar: {
    active: false,
  },
  sidebar: {
    active: false,
  },
}
