import { media, style } from 'typestyle'

/* Constants ==================================================================================== */
import { ZINDEX_10 } from '../__constants/z-index'
import { HEADER_HEIGHT } from './app-layout.constants'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for header
   */
  base: string
  /**
   * Proped class for header
   */
  proped: {
    active: string
    offsetted: string
    loaded: string
  }
}

type ClassProps = {
  leftOffset?: number
  topOffset?: number
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { leftOffset, topOffset } = props

  const base = style({
    alignItems: 'center',
    display: 'flex',
    height: 0,
    overflow: 'hidden',
    position: 'fixed',
    zIndex: ZINDEX_10,
    ...media(
      { type: 'print' },
      {
        display: 'none',
      },
    ),
  })

  const proped = {
    active: style({
      height: HEADER_HEIGHT,
      overflow: 'visible',
    }),
    offsetted: style({
      left: leftOffset,
      top: topOffset,
      width: `calc(100% - ${leftOffset}px)`,
    }),
    loaded: style({
      transition: theme.transition.medium,
      transitionProperty: 'top, left, width',
    }),
  }

  return {
    base,
    proped,
  }
}

/* Export ======================================================================================= */
export const headerClass = new StyleClass<ClassNames, ClassProps>(getNames)
