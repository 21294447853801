import loadable from '@loadable/component'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

/* Common ======================================================================================= */
import config from '../../../config'

/* Contexts ===================================================================================== */
import NavigationWrapper from '../../contexts/navigation'

/* Components =================================================================================== */
import { Modal } from '../../components/modal'
import { Sidebar } from '../../components/sidebar'

/* Routes ======================================================================================= */
import pages from '../../../routes'
import { CacheContextProvider } from '../../contexts/cache-context'

export const AsyncPage: any = (props: {
  name: string
  path?: string
  injection?: any
  public?: boolean
  component?: any
  exact?: boolean
  state?: any
}) => {
  const { path, injection, name, component, state } = props
  const location = useLocation()
  const Component = component || loadable(() => import(`./${name}`))
  const seo = path
    ? (config.seo as any)[path || 'default'] || config.seo.default
    : config.seo.default

  function inject() {
    if (location.search.includes('auth=1')) {
      return {}
    }

    if (typeof globalThis.window !== undefined) {
      if ((globalThis.window as any) && (globalThis.window as any).__aeki__) {
        const injection = { ...(globalThis.window as any).__aeki__ }
        return injection || state
      }
    }

    return state
  }

  if (props.public) {
    return (
      <>
        {/* <Helmet>
          <title>{seo.title}</title>
        </Helmet> */}
        <Component
          injection={injection}
          inject={inject}
          eject={() => {
            ;(window as any).__aeki__ = null
          }}
        />
        <Modal.render />
      </>
    )
  }

  return (
    <CacheContextProvider>
      <NavigationWrapper key={typeof window !== 'undefined' ? window.location.pathname : ''}>
        <Component
          injection={injection}
          inject={inject}
          eject={() => {
            ;(window as any).__aeki__ = null
          }}
        />
        <Modal.render />
        <Sidebar.render />
      </NavigationWrapper>
    </CacheContextProvider>
  )
}

export const _AsyncPage = (props: any) => {
  const { injection, name, component, state = {} } = props
  const location = useLocation()
  const Component = component || loadable(() => import(`./${name}`))

  function inject() {
    if (location.search.includes('auth=1')) {
      return {}
    }

    if (typeof globalThis.window !== undefined) {
      if ((globalThis.window as any) && (globalThis.window as any).__aeki__) {
        const injection = { ...(globalThis.window as any).__aeki__ }
        return injection || state
      }
    }

    return state
  }

  return (
    <>
      <Component
        injection={injection}
        inject={inject}
        eject={() => {
          ;(window as any).__aeki__ = null
        }}
      />
      <Modal.render />
    </>
  )
}

export default (state: any) => {
  return (
    <Routes>
      {/* Auth Related */}
      <Route path="/a/signin" element={<AsyncPage name={'user-signin'} state={state} public />} />
      <Route
        path="/a/login-loading"
        element={<AsyncPage name={'user-signin-loading'} state={state} public />}
      />
      <Route
        path="/a/register"
        element={<AsyncPage name={'user-register'} state={state} public />}
      />
      <Route
        path="/a/register-third-party"
        element={<AsyncPage name={'user-register-third-party'} state={state} public />}
      />
      <Route
        path="/a/password-forget"
        element={<AsyncPage name={'user-password-forget'} state={state} public />}
      />
      <Route
        path="/a/password-forget"
        element={<AsyncPage name={'user-password-forget'} state={state} public />}
      />
      <Route
        path="/a/users/password-reset/:passwordResetLink"
        element={<AsyncPage name={'user-password-reset'} state={state} public />}
      />
      <Route
        path="/a/confirmation"
        element={<AsyncPage name={'user-confirmation'} state={state} public />}
      />
      <Route
        path="/a/verify-form/:verificationToken"
        element={<AsyncPage name={'user-verify'} state={state} public />}
      />

      {/* Page */}
      <Route
        path="/a/groups/:groupId/page/:pageId"
        element={<AsyncPage name={'page'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/page/:pageId/parent/:parentRowId"
        element={<AsyncPage name={'page'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/:rowId/edit"
        element={<AsyncPage name={'page'} state={state} />}
      />

      {/* Form */}
      <Route
        path="/a/groups/:groupId/base/:baseId/table/:rowId/edit"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/:rowId/edit"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/table/new"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/new"
        element={<AsyncPage name={'form'} state={state} />}
      />

      {/* Row */}
      <Route
        path="/a/groups/:groupId/base/:baseId/table/:rowId/upload"
        element={<AsyncPage name={'upload'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/table/:rowId"
        element={<AsyncPage name={'row'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/:rowId"
        element={<AsyncPage name={'row'} state={state} />}
      />

      {/* Table */}
      <Route
        path="/a/groups/:groupId/base/:baseId/table"
        element={<AsyncPage name={'table'} state={state} />}
      />
      <Route
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table"
        element={<AsyncPage name={'table'} state={state} />}
      />

      {/* Group Related */}
      <Route
        path="/a/groups/:groupId/all"
        element={<AsyncPage name={'group-all'} state={state} />}
      />
      <Route path="/a/groups/:groupId/base" element={<AsyncPage name={'app'} state={state} />} />
      <Route path="/a/groups/:groupId" element={<AsyncPage name={'dashboard'} state={state} />} />
      <Route path="/a/groups" element={<AsyncPage name={'groups'} state={state} />} />

      {/* User Related */}
      <Route
        path="/a/user/page/:pageId/parent/:parentRowId"
        element={<AsyncPage name={'page'} state={state} />}
      />
      <Route path="/a/user/page/:pageId" element={<AsyncPage name={'page'} state={state} />} />
      <Route
        path="/a/user/base/:baseId/table/:rowId/edit"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/user/base/:baseId/table/new"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/user/base/:baseId/table/:rowId"
        element={<AsyncPage name={'row'} state={state} />}
      />
      <Route
        path="/a/user/base/:baseId/table"
        element={<AsyncPage name={'table'} state={state} />}
      />

      <Route
        path="/a/user/base/:baseId/parent/:parentRowId/table/:rowId/edit"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/user/base/:baseId/parent/:parentRowId/table/new"
        element={<AsyncPage name={'form'} state={state} />}
      />
      <Route
        path="/a/user/base/:baseId/parent/:parentRowId/table/:rowId"
        element={<AsyncPage name={'row'} state={state} />}
      />
      <Route
        path="/a/user/base/:baseId/parent/:parentRowId/table"
        element={<AsyncPage name={'table'} state={state} />}
      />
      <Route path="/a/user/inbox" element={<AsyncPage name={'app'} state={state} />} />
      <Route path="/a/user" element={<AsyncPage name={'dashboard'} state={state} />} />

      <Route path="/page-not-found" element={<AsyncPage name={'page-not-found'} state={state} />} />

      {/* Custom */}
      {pages(state).props.children}

      <Route element={<AsyncPage name={'page-not-found'} state={state} />} />
    </Routes>
  )
}
