import { classes } from 'typestyle'
import { Box } from '../box/box.component'
import { lineClass } from './line.class'

export const Line = (props: {
  horiz?: boolean
  baseline?: string
  pad?: number
  className?: string
  dark?: boolean
  thick?: boolean
}) => {
  const { className, horiz, pad = 0, dark, thick } = props

  const { baseThemed } = lineClass.setProps({ horiz, dark, thick })

  if (horiz) {
    return (
      <Box
        className={classes(baseThemed, className)}
        minWidth={thick ? 4 : 1}
        maxWidth={thick ? 4 : 1}
        minHeight={`calc(100% - ${pad * 2}px)`}
        maxHeight={`calc(100% - ${pad * 2}px)`}
      />
    )
  }

  return (
    <Box
      className={classes(baseThemed, className)}
      minWidth={`calc(100% - ${pad * 2}px)`}
      maxWidth={`calc(100% - ${pad * 2}px)`}
    />
  )
}
