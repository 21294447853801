import { useContext, useEffect, useMemo } from 'react'
import { Box } from '../../__aeki__/components/box/box.component'
import { TextField } from '../../__aeki__/components/text-field'
import { Text } from '../../__aeki__/components/text/text.component'
import { addField } from '../../__aeki__/core/field-manager'
import useValue from '../../__aeki__/hooks/use-value'
import { mt4 } from '../../__aeki__/styles/styleset/margin/mt4'
import { Select } from '../../__aeki__/components/select'
import { FormContext } from '../../__aeki__/components/form'
import { StaticField } from '../../__aeki__/components/static-field'

const QUESTIONS = [
  {
    id: 0,
    question: 'How did you hear about us?',
    type: 'select',
    options: ['Google', 'Facebook', 'Instagram', 'LinkedIn', 'Family/Friend', 'Other'],
  },
  // {
  //   id: 1,
  //   question: 'What is the most difficult part of finding a childcare?',
  //   type: 'text',
  // },
]

const Survey = props => {
  const [value, $value] = useValue('')
  const [secondaryValue, $secondaryValue] = useValue('')
  const selectedQuestion = useMemo(
    () => QUESTIONS[Math.floor(Math.random() * QUESTIONS.length)],
    [],
  )

  const renderField = () => {
    switch (selectedQuestion.type) {
      case 'select':
        return (
          <Box className={mt4}>
            <Select
              // value={value}
              onChange={value => {
                $value.set(value)
              }}
              options={selectedQuestion.options}
              fullWidth
            />
            {value === 'Other' && (
              <TextField
                placeholder="Please specify"
                className={mt4}
                value={secondaryValue}
                onChange={value => {
                  $secondaryValue.set(value)
                }}
              />
            )}
          </Box>
        )
      default:
        return (
          <Box className={mt4}>
            <TextField
              className={mt4}
              value={value}
              onChange={value => {
                $value.set(value)
              }}
            />
          </Box>
        )
    }
  }

  return (
    <Box>
      <Text>{selectedQuestion.question}</Text>
      {renderField()}
      <StaticField
        name="survey"
        value={value ? `[${selectedQuestion.id}]${value}` : ''}
        validate={props.validate}
      />
      {value === 'Other' && (
        <StaticField name="survey-detail" value={secondaryValue} validate={props.validate} />
      )}
    </Box>
  )
}

export default addField('Survey', {
  field: Survey,
})
