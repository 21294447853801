import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { createMediaQuery } from '../style-class/style-class.utils'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

type ClassNames = {
  /**
   * Base class for text field
   */
  base: string
  /**
   * Themed class for text field
   */
  themed: string
  /**
   * Proped class for text field
   */
  proped: {
    focused: string
  }
}

type ClassProps = {
  baseline: ComponentBaselineType
  freeSize?: boolean
  children?: any
  alignRight?: boolean
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline, children, alignRight } = props
  const base = style({
    // background: theme.getBaseline(baseline).color.medium,
    cursor: 'text',
    flexWrap: 'wrap',
    outline: 'none',
    // padding: children ? (alignRight ? '0px 0px 0 12px' : '0px 12px 0 0px') : '0px 4px 0 12px',
    borderRadius: 4,
    height: 42,
    letterSpacing: 0,
    width: '100%',
    $nest: {
      '& input': {
        padding: children ? (alignRight ? '0px 0px 0 12px' : '0px 12px 0 0px') : '0px 4px 0 12px',
        // fontSize: '16px !important',
        flex: 1,
        height: '100%',
        background: 'none',
        border: 'none',
        outline: 'none',
        // textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        $nest: {
          '&::-webkit-input-placeholder': {
            color: 'grey !important',
            fontSize: 14,
          },
          ...createMediaQuery.sm({
            fontSize: '16px !important',
          }),
        },
      },
    },
  })

  const themed = style({
    background: theme.getBaseline(baseline).color.medium,
    $nest: {
      '*': {
        color: theme.getBaseline(baseline).contrast.medium,
      },
    },
  })

  const proped = {
    focused: style({
      background: theme.getBaseline('background').color.dark,
    }),
  }

  return {
    base,
    themed,
    proped,
  }
}

/* Export ======================================================================================= */
export const textFieldClass = new StyleClass<ClassNames, ClassProps>(getNames)
