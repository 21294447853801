import { classes, style, media } from 'typestyle'
import { f18i } from '../styleset/font-size/f18i'
import { pb16 } from '../styleset/padding/pb16'
import { pb16mdi } from '../styleset/padding/pb16mdi'
import { pl12mdi } from '../styleset/padding/pl12mdi'
import { pl12smi } from '../styleset/padding/pl12smi'
import { pl16 } from '../styleset/padding/pl16'
import { pl16mdi } from '../styleset/padding/pl16mdi'
import { pl20 } from '../styleset/padding/pl20'
import { pl4mdi } from '../styleset/padding/pl4mdi'
import { pl8 } from '../styleset/padding/pl8'
import { pl8smi } from '../styleset/padding/pl8smi'
import { pr12mdi } from '../styleset/padding/pr12mdi'
import { pr12smi } from '../styleset/padding/pr12smi'
import { pr16 } from '../styleset/padding/pr16'
import { pr16mdi } from '../styleset/padding/pr16mdi'
import { pr20 } from '../styleset/padding/pr20'
import { pr8smi } from '../styleset/padding/pr8smi'
import { pt16 } from '../styleset/padding/pt16'
import { pt16mdi } from '../styleset/padding/pt16mdi'

const transition = style({
  // transitionProperty: 'background, padding !important',
})

export const paddingVerticalOuter = classes(
  pl12mdi,
  pr12mdi,
  pl8smi,
  pr8smi,
  pl16,
  pr16,
  transition,
)
export const paddingLeftOuter = classes(pl12mdi, pl8smi, pl16, transition)
export const paddingRightOuter = classes(pr12mdi, pr8smi, pr16, transition)
export const paddingLeftOuterSmall = classes(pl4mdi, pl8)
export const paddingVerticalInner = classes(
  pl16mdi,
  pr16mdi,
  pl8smi,
  pr8smi,
  pl20,
  pr20,
  transition,
)
export const paddingLeftInner = classes(
  transition,
  pl16mdi,
  pr16mdi,
  pl12smi,
  pr12smi,
  pl16,
  pr16,
  style({ ...media({ type: 'print' }, { padding: '0 !important' }) }),
)
export const paddingHorizontalInner = classes(
  pt16,
  pb16,
  pt16mdi,
  pb16mdi,
  style({ ...media({ type: 'print' }, { padding: '0 !important' }) }),
)

export const iconResponsive = classes(f18i)
