import { FC } from 'react'
import { style, classes } from 'typestyle'
import { useNavigate, Link, useLocation } from 'react-router-dom'

/* Components =================================================================================== */
import { Box } from '../../components/box/box.component'
import { Button } from '../../components/button'

/* Types ======================================================================================== */
import { ComponentSizeType } from '../../components/__core/component.types'

/* Styles ======================================================================================= */
import { mr8 } from '../../styles/styleset/margin/mr8'
import { mr4 } from '../../styles/styleset/margin/mr4'
import { ml4 } from '../../styles/styleset/margin/ml4'
import { f18i } from '../../styles/styleset/font-size/f18i'
import { fitWidth } from '../../styles/styleset/fit-width/fitWidth'
import { fw700 } from '../../styles/styleset/font-weight/fw700'
import { fw400 } from '../../styles/styleset/font-weight/fw400'
import { Tooltip } from '../../components/tooltip'

export const Pagination: FC<{
  data: any
  $data: any
  size?: ComponentSizeType
  isLink?: boolean
}> = ({ data, $data, isLink, size = 'xs' }) => {
  const location = useLocation()
  const url = `${location.pathname}${location.search}`

  if (data.payload.count < 1 || !data.payload.count) {
    return <div />
  }

  return (
    <Box flex baseline="none" alignCenterX>
      <Box baseline="none">
        <Button
          as={
            isLink &&
            data.search.page - 1 > 0 && (
              <Link
                title="Previous Page"
                to={url.replace(`page=${data.search.page}`, `page=${data.search.page - 1}`)}
              />
            )
          }
          ariaLabel="Previous Page"
          icon={
            <i className={classes(process.env.REACT_APP_GOOGLE_MATERIAL_ICONS, f18i)}>
              keyboard_arrow_left
            </i>
          }
          size={size}
          baseline="none"
          onClick={() => {
            if (!isLink && data.search.page - 1 > 0) {
              $data.get({
                search: {
                  page: data.search.page - 1,
                },
                updateGetParams: true,
              })
            }
          }}
        />
      </Box>
      {(() => {
        const pageButtons: any = []

        const minPage = Math.max(0, Math.floor(data.search.page / 10) * 10 - 1)
        const maxPage = Math.min(data.payload.totalPage, minPage + 10)

        for (let i = minPage; i < maxPage; i++) {
          pageButtons.push(
            <Box className={data.search.count > 0 && mr8} key={i} baseline="none">
              <Button
                as={
                  isLink && (
                    <Link
                      title={`Go to Page ${i + 1}`}
                      to={url.replace(`page=${data.search.page}`, `page=${i + 1}`)}
                    />
                  )
                }
                className={data.search.page === i + 1 ? fw700 : fw400}
                label={`${i + 1}`}
                size={size}
                ariaLabel={`Go to Page ${i + 1}`}
                baseline={data.search.page === i + 1 ? 'primary' : 'none'}
                onClick={() => {
                  if (!isLink) {
                    $data.get({
                      search: {
                        page: i + 1,
                      },
                      updateGetParams: true,
                    })
                  }
                }}
                square
              />
            </Box>,
          )
        }

        return pageButtons
      })()}
      <Box baseline="none">
        <Button
          as={
            isLink &&
            data.search.page < data.payload.totalPage && (
              <Link
                title="Next Page"
                to={
                  url.includes('page=1')
                    ? url.replace(`page=${data.search.page}`, `page=${data.search.page + 1}`)
                    : `${url}?page=2`
                }
              />
            )
          }
          icon={
            <i className={classes(process.env.REACT_APP_GOOGLE_MATERIAL_ICONS, f18i)}>
              keyboard_arrow_right
            </i>
          }
          ariaLabel="Next Page"
          size={size}
          baseline="none"
          onClick={() => {
            if (!isLink && data.search.page < data.payload.totalPage) {
              $data.get({
                search: {
                  page: data.search.page + 1,
                },
                updateGetParams: true,
              })
            }
          }}
        />
      </Box>
    </Box>
  )
}

export const SimplerPagination: FC<{
  data: any
  $data: any
  size?: ComponentSizeType
  float?: boolean
  isLink?: boolean
  url?: string
  getTopOffset?: () => any
}> = (props: any) => {
  const { data, $data, size = 'md', isLink, getTopOffset } = props
  const location = useLocation()
  const url = props.url || `${location.pathname}${location.search}`

  const prevButton = (
    <Button
      className={mr4}
      label={'Prev'}
      baseline={data.payload.page === 1 || data.payload.data.length === 0 ? 'disabled' : 'surface'}
      onClick={() => {
        if (typeof window !== 'undefined') {
          window.scrollTo({ top: getTopOffset ? getTopOffset() : 0, behavior: 'smooth' })
        }

        if (!isLink && data.search.page - 1 > 0) {
          $data.get({
            search: {
              page: data.search.page - 1,
            },
            updateGetParams: true,
          })
        }
      }}
      icon={
        <span
          className={classes(
            process.env.REACT_APP_GOOGLE_MATERIAL_ICONS,
            f18i,
            style({
              color:
                data.payload.page === 1 || data.payload.data.length === 0
                  ? 'inherit !important'
                  : 'black',
            }),
          )}
        >
          keyboard_arrow_left
        </span>
      }
      size={size}
      square
      float
    />
  )

  const nextButton = (
    <Button
      className={ml4}
      baseline={data.payload.totalPage === data.payload.page ? 'disabled' : 'surface'}
      iconPosition={'right'}
      onClick={() => {
        if (typeof window !== 'undefined') {
          window.scrollTo({ top: getTopOffset ? getTopOffset() : 0, behavior: 'smooth' })
        }
        if (!isLink && data.search.page < data.payload.totalPage) {
          $data.get({
            search: {
              page: data.search.page + 1,
            },
            updateGetParams: true,
          })
        }
      }}
      icon={
        <span
          className={classes(
            process.env.REACT_APP_GOOGLE_MATERIAL_ICONS,
            f18i,
            style({
              color:
                data.payload.data.length === 0 || data.payload.totalPage === data.search.page
                  ? 'inherit !important'
                  : 'black',
            }),
          )}
        >
          keyboard_arrow_right
        </span>
      }
      size={size}
      label="Next"
      square
      float
    />
  )

  if (isLink) {
    return (
      <Box className={fitWidth} flex baseline="none">
        {data.payload.page != 1 ? (
          <Link
            title="Previous Page"
            to={url.replace(`page=${data.search.page}`, `page=${data.search.page - 1}`)}
          >
            {prevButton}
          </Link>
        ) : (
          prevButton
        )}
        {data.payload.totalPage != data.payload.page ? (
          <Link
            title="Next Page"
            to={url.replace(`page=${data.search.page}`, `page=${data.search.page + 1}`)}
          >
            {nextButton}
          </Link>
        ) : (
          nextButton
        )}
      </Box>
    )
  }

  return (
    <Box className={fitWidth} flex baseline="none">
      {prevButton}
      {nextButton}
    </Box>
  )
}

export const EvenMoreSimplerPagination: FC<{
  data: any
  $data: any
  size?: ComponentSizeType
  float?: boolean
  isLink?: boolean
  url?: string
}> = (props: any) => {
  const { data, $data, size = 'md', isLink } = props

  return (
    <Box className={fitWidth} flex baseline="none">
      <Tooltip
        label="Prev page"
        disabled={data.payload.page === 1 || data.payload.data.length === 0}
      >
        <Button
          className={mr4}
          icon={
            <span
              className={classes(
                'material-icons',
                f18i,
                style({
                  color:
                    data.payload.page === 1 || data.payload.data.length === 0
                      ? 'inherit !important'
                      : 'black',
                }),
              )}
            >
              keyboard_arrow_left
            </span>
          }
          size={size}
          baseline={
            data.payload.page === 1 || data.payload.data.length === 0 ? 'disabled' : 'surface'
          }
          onClick={() => {
            if (!isLink && data.search.page - 1 > 0) {
              $data.get({
                ...(() => {
                  if (props.url) {
                    return {
                      url: props.url,
                    }
                  }
                  return {}
                })(),
                search: {
                  page: data.search.page - 1,
                },
                updateGetParams: true,
              })
            }
          }}
          alt={data.payload.page === 1 || data.payload.data.length === 0}
        />
      </Tooltip>
      <Tooltip label="Next page" disabled={data.payload.totalPage === data.payload.page}>
        <Button
          icon={
            <span
              className={classes(
                'material-icons',
                f18i,
                style({
                  color:
                    data.payload.data.length === 0 || data.payload.totalPage === data.search.page
                      ? 'inherit !important'
                      : 'black',
                }),
              )}
            >
              keyboard_arrow_right
            </span>
          }
          size={size}
          alt={data.payload.totalPage === data.payload.page}
          baseline={data.payload.totalPage === data.payload.page ? 'disabled' : 'surface'}
          iconPosition={'right'}
          onClick={() => {
            if (!isLink && (data.search.page || 0) < data.payload.totalPage) {
              $data.get({
                ...(() => {
                  if (props.url) {
                    return {
                      url: props.url,
                    }
                  }
                  return {}
                })(),
                search: {
                  page: data.search.page + 1,
                },
                updateGetParams: true,
              })
            }
          }}
        />
      </Tooltip>
    </Box>
  )
}
