import { useContext, useMemo } from 'react'

import * as React from 'react'

/* Components =================================================================================== */
import { FormContext } from '../form'

/* Types ======================================================================================== */
import { ComponentProps, ComponentSizeType } from '../__core/component.types'
import { get } from '../../share/find'

export type FieldProps = {
  className?: string
  compact?: boolean
  data?: string
  disableError?: boolean
  label?: string
  name?: string
  focused?: boolean
  onChange?: (data: any, form?: any) => void
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement & HTMLTextAreaElement>) => void
  onUnmount?: any
  validate?: any
  sanitize?: any
  formValue?: any
  value?: any
  placeholder?: string
  defaultValue?: any
  style?: React.CSSProperties
  beforeSubmit?: (value: any, update: any) => void
  size?: ComponentSizeType
  start?: any
  end?: any
  wrapper?: any
  wrapperProps?: any
  options?: string[]
} & ComponentProps

export type FieldContextProps = {
  error?: string
  meta?: any
  /** Followings are set required so there is no extra validation  */
  onChange: (data: any, form: any) => void
  onFocus: () => void
  onBlur: () => void
  dispatch: (props: { type: string; param?: any }) => any
  field: {
    focused: boolean
    validate: any
  }
  handleValidate: any
  handleValue: any
  children?: any
} & FieldProps

export type NumberFieldContextProps = FieldContextProps & {
  step?: number
  min?: number
  max?: number
  disableHelper?: boolean
}

const defaultProps: object = {}
/* <Field /> ==================================================================================== */
export const withField: (
  FieldElement: any,
  componentClass?: string,
) => React.FC<any> = FieldElement => {
  const ComposedField: React.FC<FieldProps> = props => {
    const { value, dispatch } = useContext(FormContext)

    const handleValidate = async (value: any, validate?: any) => {
      const validators = validate || []
      let error: any

      if (typeof validators === 'function') {
        return await validators(value)
      } else {
        for (let i = 0; i < validators.length; i++) {
          if (value && typeof value === 'object' && value.error) {
            error = await validators[i](value.value)
          } else {
            error = await validators[i](value)
          }

          if (error) {
            if (typeof value === 'object' && value.error) {
              return value
            } else {
              return { value, error }
            }
          } else {
            return value
          }
        }
      }

      return value
    }

    const handleValue = (value: any) => {
      if (value?.error !== undefined) {
        if (!value.value) {
          return ''
        }

        return value.value
      }

      return value
    }

    const getValue = () => {
      const tempArray = get(props.name as any, value.raw, 'array')

      if (tempArray.length > 0) {
        return [tempArray]
      }

      return [(value.form as any)[props.name as any]]
    }

    return useMemo(() => {
      const component = (
        <FieldElement
          {...props}
          formValue={props.value || (props.name && (value.form as any)[props.name])}
          field={props.name && (value.fields as any)[props.name as any]}
          dispatch={dispatch}
          handleValidate={handleValidate}
          handleValue={handleValue}
          getContext={() => {
            return {
              value,
              dispatch,
            }
          }}
        />
      )

      if (props.wrapper) {
        const Wrapper = props.wrapper

        return (
          <Wrapper
            formValue={props.value || (props.name && (value.form as any)[props.name])}
            field={props.name && (value.fields as any)[props.name as any]}
            dispatch={dispatch}
            handleValidate={handleValidate}
            handleValue={handleValue}
            {...props.wrapperProps}
          >
            <FieldElement
              {...props}
              formValue={props.value || (props.name ? (value.form as any)[props.name] : undefined)}
              field={props.name && (value.fields as any)[props.name as any]}
              dispatch={dispatch}
              handleValidate={handleValidate}
              handleValue={handleValue}
            />
          </Wrapper>
        )
      }

      return component
    }, [
      ...getValue(),
      (value.fields as any)[props.name as any],
      props.value,
      props.onChange,
      props.beforeSubmit,
      props.disabled,
      props.focused,
      props.validate,
      props.options,
      props.defaultValue,
    ])
  }

  ComposedField.defaultProps = defaultProps

  return ComposedField
}
