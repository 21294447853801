import { ReactNode, useState } from 'react'
import {
  useFloating,
  offset,
  shift,
  autoUpdate,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  flip,
} from '@floating-ui/react'
import { classes } from 'typestyle'

/* Components =================================================================================== */
import { Box } from '../box/box.component'
import { Text } from '../text/text.component'

/* Styles ======================================================================================= */
import { p8 } from '../../styles/styleset/padding/p8'
import { pb4 } from '../../styles/styleset/padding/pb4'
import { pt4 } from '../../styles/styleset/padding/pt4'

export const Tooltip = ({
  children,
  label,
  disabled,
}: {
  children: ReactNode
  label: string
  disabled?: boolean
}) => {
  const [open, setOpen] = useState(false)

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  })

  const hover = useHover(context, {
    mouseOnly: true,
    delay: {
      open: 200,
      close: 0,
    },
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  return (
    <>
      <span ref={reference} {...getReferenceProps()}>
        {children}
      </span>
      {open && !disabled && (
        <Box
          className={classes(p8, pt4, pb4)}
          baseline="secondary"
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
            // position: 'fixed',
            zIndex: 10,
          }}
          rounded
          {...getFloatingProps()}
        >
          <Text size="sm" shrink>
            {label}
          </Text>
        </Box>
      )}
    </>
  )
}
