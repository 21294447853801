import { ComponentBaselineType } from '../__core/component.types'
import config from '../../../config'

let _DefaultTheme: any = (config.theme && config.theme[0]) || {
  baselines: {
    primary: {
      color: {
        light: '#e3f2fd',
        medium: '#6599da',
        dark: '#3979ca',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    secondary: {
      color: {
        light: '#312c2c',
        medium: '#312c2c',
        dark: '#000000',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    secondaryAlt: {
      color: {
        light: '#312c2c',
        medium: '#312c2c',
        dark: '#000000',
      },
      contrast: {
        light: '#90a4ae',
        medium: '#7e8e96',
        dark: '#90a4ae',
      },
    },
    background: {
      color: {
        light: 'rgb(239, 239, 239)',
        medium: 'rgb(238,238,238)',
        dark: 'rgb(230,230,230)',
      },
      contrast: {
        light: 'black',
        medium: 'black',
        dark: 'black',
      },
    },
    backgroundAlt: {
      color: {
        light: 'rgb(95, 96, 127)',
        medium: 'rgb(95, 96, 127)',
        dark: 'rgb(95, 96, 127)',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    surface: {
      color: {
        light: 'white',
        medium: 'white',
        dark: 'rgb(239, 239, 239)',
      },
      contrast: {
        light: 'black',
        medium: 'black',
        dark: 'black',
      },
    },
    error: {
      color: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
      contrast: {
        light: 'red',
        medium: 'red',
        dark: 'red',
      },
    },
    errorAlt: {
      color: {
        light: 'rgb(217, 147, 47)',
        medium: 'rgb(217, 147, 47)',
        dark: 'rgb(217, 147, 47)',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    disabled: {
      color: {
        light: 'rgb(230,230,230)',
        medium: 'rgb(230,230,230)',
        dark: 'rgb(230,230,230)',
      },
      contrast: {
        light: 'rgb(120,120,120)',
        medium: 'rgb(120,120,120)',
        dark: 'rgb(120,120,120)',
      },
    },
    disabledAlt: {
      color: {
        light: 'rgb(72 153 166)',
        medium: 'rgb(72 153 166)',
        dark: 'rgb(72 153 166)',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    none: {
      color: {},
      contrast: {},
    },
  },
  boxes: {
    border: {
      light: 'rgb(240,240,240)',
      medium: 'rgb(230,230,230)',
      dark: 'rgb(220,220,220)',
    },
    shadow: {
      light: 'rgba(0,0,0,0.05)',
      medium: 'rgba(0,0,0,0.1)',
      dark: 'rgba(0,0,0,0.15)',
    },
  },
  transition: {
    slow: '0.5s cubic-bezier(0.19, 1, 0.22, 1)',
    medium: '0.35s cubic-bezier(0.19, 1, 0.22, 1)',
    fast: '0.1s cubic-bezier(0.19, 1, 0.22, 1)',
  },
}

_DefaultTheme = {
  ..._DefaultTheme,
  getBaseline: (name: ComponentBaselineType, alt?: boolean) => {
    const baseline: any = {}

    if (alt && _DefaultTheme.baselines[`${name}Alt`]) {
      return _DefaultTheme.baselines[`${name}Alt`]
    }

    return _DefaultTheme.baselines[name]
  },
}

export const DefaultTheme = _DefaultTheme
