import { FC } from 'react'
import { classes, stylesheet } from 'typestyle'

/* Components =================================================================================== */
import { Box } from '../../__aeki__/components/box/box.component'
import { Text } from '../../__aeki__/components/text/text.component'
import { A } from '../../__aeki__/components/a'

/* Constants ==================================================================================== */
import { MAX_WIDTH } from '../../routes/__home__/layout'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../../__aeki__/components/style-class/style-class.utils'
import { m0mdi } from '../../__aeki__/styles/styleset/margin/m0mdi'
import { mb16 } from '../../__aeki__/styles/styleset/margin/mb16'
import { ml24 } from '../../__aeki__/styles/styleset/margin/ml24'
import { mr0smi } from '../../__aeki__/styles/styleset/margin/mr0smi'
import { mr48 } from '../../__aeki__/styles/styleset/margin/mr48'
import { mt36 } from '../../__aeki__/styles/styleset/margin/mt36'
import { mt8 } from '../../__aeki__/styles/styleset/margin/mt8'
import { mt8mdi } from '../../__aeki__/styles/styleset/margin/mt8mdi'
import { p8 } from '../../__aeki__/styles/styleset/padding/p8'
import { pb12 } from '../../__aeki__/styles/styleset/padding/pb12'
import { pl0 } from '../../__aeki__/styles/styleset/padding/pl0'
import { primaryDarki } from '../../__aeki__/styles/styleset/primary-dark/primaryDarki'
import { pt16mdi } from '../../__aeki__/styles/styleset/padding/pt16mdi'
import { pt40 } from '../../__aeki__/styles/styleset/padding/pt40'
import { pt8 } from '../../__aeki__/styles/styleset/padding/pt8'
import { w100 } from '../../__aeki__/styles/styleset/width/w100'

/* Types ======================================================================================== */
type FooterType = {
  list?: { title: string; pages: { title: string; to: string }[] }[]
  isGroupSite?: boolean
}

/* Constants ==================================================================================== */
const FOOTER_ITEMS = [
  {
    title: process.env.REACT_APP_PROJECT_NAME,
    className: mr48,
    pages: [
      {
        title: 'Home',
        to: '/',
      },
    ],
  },
  {
    title: 'Support',
    className: ml24,
    pages: [
      {
        title: 'Contact us',
        to: '/a/contact-us',
      },
    ],
  },
]

/* <Footer /> =================================================================================== */
const Footer: FC<FooterType> = ({ isGroupSite }) => {
  return (
    <Box
      className={classes(isGroupSite ? '' : pt40, isGroupSite ? '' : pt16mdi, pb12)}
      baseline="background"
      row
      fullWidth
    >
      <Box maxWidth={MAX_WIDTH} centerAutoX baseline={'none'} fullWidth>
        <FooterContent isGroupSite={isGroupSite} />
      </Box>
    </Box>
  )
}

/* <FooterContent /> ============================================================================ */
export const FooterContent = (props: {
  row?: boolean
  className?: string
  isGroupSite?: boolean
}) => {
  const { row, className } = props

  return (
    <Box baseline="background" className={p8}>
      {!props.isGroupSite && (
        <Box
          className={classes(p8, STYLES.containerFooterContent, className)}
          row={row}
          baseline="background"
        >
          {FOOTER_ITEMS.map((menu: any, key: any) => {
            return (
              <Box
                className={classes(STYLES.containerFooterItem, mr0smi, w100, m0mdi, menu.className)}
                baseline="background"
                key={key}
              >
                <Text size="sm" semiBold lighter baseline="surface">
                  {menu.title}
                </Text>
                <ul className={classes(mt8, pl0)}>
                  {menu.pages.map((page: any, key: number) => {
                    return (
                      <Text
                        className={classes(mb16, STYLES.textFooterItem)}
                        size="sm"
                        as={<li />}
                        key={key}
                        listLink
                      >
                        <A to={(page as any).to} target={page.target}>
                          {(page as any).title}
                        </A>
                      </Text>
                    )
                  })}
                </ul>
              </Box>
            )
          })}
        </Box>
      )}
      <Box baseline="background">
        {props.isGroupSite && (
          <Box
            className={classes(mt36, mt8mdi, pt8, STYLES.textCopyRight)}
            baseline="none"
            maxWidth={MAX_WIDTH}
            centerAutoX
          >
            <Text size="md">
              This page is provided by{' '}
              <Text link>
                <A
                  to="/"
                  title={`Go to ${process.env.REACT_APP_PROJECT_NAME} Home Page`}
                  className={primaryDarki}
                >
                  {process.env.REACT_APP_PROJECT_NAME}
                </A>
              </Text>
            </Text>
          </Box>
        )}
        <Box
          className={classes(mt36, mt8mdi, pt8, STYLES.textCopyRight)}
          baseline="none"
          maxWidth={MAX_WIDTH}
          centerAutoX
        >
          <Text size="xs">© 2023 {process.env.REACT_APP_PROJECT_NAME}. All rights reserved.</Text>
        </Box>
      </Box>
    </Box>
  )
}

/* Styles ======================================================================================= */
const STYLES = stylesheet({
  containerFooterContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',

    $nest: {
      ...createMediaQuery['md']({
        margin: 0,
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }),
      '& a': {
        // color: COOL_GRAY,
      },
    },
  },
  containerFooterItem: {
    width: 'fit-content',
  },
  textFooterItem: {
    display: 'block',
  },
  textCopyRight: {
    textAlign: 'center',
    // color: COOL_GRAY,
  },
})

export default Footer
