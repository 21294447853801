import { classes, stylesheet } from 'typestyle'

/* Components =================================================================================== */
import { A } from '../../__aeki__/components/a'

export const LogoSmall = null

export const Logo = (props: { withSmallLogo?: boolean; toggled?: boolean; className?: string }) => {
  const { toggled, className } = props

  return (
    <A
      title={`Go to ${process.env.REACT_APP_PROJECT_NAME} Home`}
      // ariaLabel={`Go to ${process.env.REACT_APP_PROJECT_NAME} Home`}
      to="/"
      className={classes(STYLES.logo, className)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.94 49.24" height="22">
        <defs>
          <style>
            {`.cls-1{fill:none;}.cls-2,.cls-3{fill:${
              toggled ? '#39374a' : 'white'
            };}.cls-2,.cls-4,.cls-6{fill-rule:evenodd;}.cls-4{fill: ${
              toggled ? '#62acdf' : '#62acdf'
            };}.cls-5{fill:${toggled ? '#3d82c4' : '#3d82c4'};}.cls-6,.cls-8{fill:${
              toggled ? '#ffce16' : '#ffce16'
            };}.cls-7{fill:${toggled ? '#f9a349' : '#f9a349'};}`}
          </style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="c">
              <rect className="cls-1" y="4.41" width="27.97" height="44.83" />
              <path
                className="cls-2"
                d="M27.44,42.78c-.06.09-.11.18-.17.26a1.13,1.13,0,0,1-.1.13c-.14.2-.29.4-.45.59a14.53,14.53,0,0,1-1,1.1c-.12.13-.24.25-.37.37l-.28.25a14.21,14.21,0,0,1-3.35,2.24,5.69,5.69,0,0,1-.56.26l-.6.25a14.77,14.77,0,0,1-2,.61,15.66,15.66,0,0,1-6.43.12,14.91,14.91,0,0,1-5.57-2.34,15,15,0,0,1-5.87-8A16.69,16.69,0,0,1,.05,34.5C0,33,0,31.47.06,30A16.52,16.52,0,0,1,.71,25.7a15.28,15.28,0,0,1,1.55-3.33,15,15,0,0,1,9.79-6.83,15.53,15.53,0,0,1,6.51.1,14.77,14.77,0,0,1,2,.61l.6.25a5.69,5.69,0,0,1,.56.26A14.42,14.42,0,0,1,25.09,19l.28.25c.13.12.25.24.37.37s.34.35.5.54.33.37.48.56.31.39.45.59a1.13,1.13,0,0,1,.1.13c.06.08.11.17.18.27a4,4,0,0,1-1.78,5.54l-.1,0a4.08,4.08,0,0,1-5-1.32,7,7,0,0,0-6.24-2.7,6.68,6.68,0,0,0-3.08,1.1,6.8,6.8,0,0,0-.94.76,7.49,7.49,0,0,0-1.91,3.05A11.94,11.94,0,0,0,8,31.71a23.44,23.44,0,0,0,.06,2.37,8.13,8.13,0,0,0,.35,2.25,7.81,7.81,0,0,0,1.91,3,6.7,6.7,0,0,0,4,1.86h0a7,7,0,0,0,6.2-2.7,4.08,4.08,0,0,1,5-1.33l.13.06A4,4,0,0,1,27.44,42.78Z"
              />
            </g>
            <g id="h">
              <rect className="cls-1" x="36.97" y="4.41" width="29.8" height="44.83" />
              <path
                className="cls-2"
                d="M48.44,15.64A14.72,14.72,0,0,0,37,30.23v4a14.9,14.9,0,0,0,7.44,13,4.05,4.05,0,0,0,.56-2v-15a7,7,0,0,1,7.87-6.95A7.21,7.21,0,0,1,59,30.54v14.7a3.94,3.94,0,0,0,.53,2,15,15,0,0,0,7.47-13v-4A15,15,0,0,0,48.44,15.64Z"
              />
              <path
                className="cls-2"
                d="M45,8.41V45.24a4.05,4.05,0,0,1-.56,2,4,4,0,0,1-7.44-2V8.41a4,4,0,1,1,8,0Z"
              />
              <path
                className="cls-2"
                d="M67,30.6V45.24a4,4,0,0,1-7.47,2,3.94,3.94,0,0,1-.53-2V30.54a4,4,0,0,1,8,.06Z"
              />
            </g>
            <g id="i">
              <rect className="cls-1" x="75.97" width="8" height="49.24" />
              <circle className="cls-3" cx="79.97" cy="8.41" r="4" />
              <rect className="cls-3" x="75.97" y="15.24" width="8" height="34" rx="4" />
            </g>
            <g id="i2">
              <rect className="cls-1" x="242.94" width="8" height="49.24" />
              <circle className="cls-3" cx="246.94" cy="8.41" r="4" />
              <rect className="cls-3" x="242.94" y="18.24" width="8" height="31" rx="4" />
            </g>
            <g id="l">
              <rect className="cls-1" x="94.97" width="8" height="49.24" />
              <rect className="cls-3" x="94.97" y="4.41" width="8" height="44.83" rx="4" />
            </g>
            <g id="d">
              <rect className="cls-1" x="111.97" width="30" height="49.24" />
              <path
                className="cls-2"
                d="M130.5,15.64A15,15,0,0,0,112,30.24v4a15,15,0,0,0,18.53,14.6A14.79,14.79,0,0,0,142,34.18V30.3A14.79,14.79,0,0,0,130.5,15.64ZM134,34.24a7,7,0,0,1-7.87,6.95A7.2,7.2,0,0,1,120,34V30.54a7.21,7.21,0,0,1,6.13-7.25,7,7,0,0,1,7.87,7Z"
              />
              <rect className="cls-3" x="133.97" y="4.41" width="8" height="44.83" rx="4" />
            </g>
            <g id="a">
              <rect className="cls-1" x="149.97" width="30" height="49.24" />
              <path
                className="cls-4"
                d="M168.5,14.86A15,15,0,0,0,150,29.46v4a15,15,0,0,0,18.53,14.6A14.79,14.79,0,0,0,180,33.4V29.52A14.79,14.79,0,0,0,168.5,14.86ZM172,33.46a7,7,0,0,1-7.87,6.95A7.2,7.2,0,0,1,158,33.17V29.76a7.21,7.21,0,0,1,6.13-7.25,7,7,0,0,1,7.87,7Z"
              />
              <circle className="cls-5" cx="175.97" cy="19.24" r="4" />
              <circle className="cls-5" cx="175.97" cy="45.24" r="4" />
              <rect className="cls-5" x="171.97" y="19.24" width="8" height="26" />
            </g>
            <g id="o">
              <rect className="cls-1" x="259.94" width="30" height="49.24" />
              <path
                className="cls-2"
                d="M278.47,15.64a15,15,0,0,0-18.53,14.6v4a15,15,0,0,0,18.53,14.6,14.79,14.79,0,0,0,11.47-14.66V30.3A14.79,14.79,0,0,0,278.47,15.64Zm3.47,18.6a7,7,0,0,1-7.87,6.95A7.2,7.2,0,0,1,267.94,34V30.54a7.21,7.21,0,0,1,6.14-7.25,7,7,0,0,1,7.86,7Z"
              />
            </g>
            <g id="dot">
              <rect className="cls-1" x="222.94" width="8" height="49.24" />
              <circle className="cls-3" cx="226.94" cy="45.24" r="4" />
            </g>
            <g id="t">
              <rect className="cls-1" x="187.97" width="26.98" height="49.24" />
              <path
                className="cls-6"
                d="M203.46,8.33v37a3.88,3.88,0,0,1-2.37,3.57,7.88,7.88,0,0,1-5.63-7.5V8.33a4,4,0,0,1,1.16-2.78,4.13,4.13,0,0,1,2.84-1.14A4,4,0,0,1,203.46,8.33Z"
              />
              <rect
                className="cls-7"
                x="197.46"
                y="5.05"
                width="8"
                height="26.98"
                rx="4"
                transform="translate(182.92 219.99) rotate(-90)"
              />
              <path
                className="cls-8"
                d="M210.69,44.65a8,8,0,0,1-7.23,4.59,8.1,8.1,0,0,1-2.37-.36,8,8,0,0,1-.69-15,2.21,2.21,0,0,1,3.06,2v3.46a2.19,2.19,0,0,0,2.2,2.2h3A2.2,2.2,0,0,1,210.69,44.65Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </A>
  )
}

const STYLES = stylesheet({
  logo: {
    display: 'flex',
    flexDirection: 'column',
  },
})
