import { useContext, createContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { classes, style } from 'typestyle'

import useValue from '../../../hooks/use-value'

import { Box } from '../../../components/box/box.component'
import { Button } from '../../../components/button'

import { p8 } from '../../../styles/styleset/padding/p8'
import { pt4 } from '../../../styles/styleset/padding/pt4'
import { pb4 } from '../../../styles/styleset/padding/pb4'
import { z2 } from '../../../styles/styleset/z-index/z2'
import { hidei } from '../../../styles/styleset/hide/hidei'
import { flexi } from '../../../styles/styleset/flex/flexi'
import Cookies from 'js-cookie'

// export const showAllhelp = () => {}

// export const toggleHelp = () => {}

export const HelpContext = createContext({} as any)

export const HelpFlag = (props: {
  identifier: string
  message: string
  top?: number
  right?: number
  className?: string
}) => {
  const { value, $value } = useContext(HelpContext)
  const [isToggled, $isToggled] = useValue(false)
  const { identifier, message, top, right } = props

  useEffect(() => {
    const toggled = Cookies.get(identifier) ? JSON.parse(Cookies.get(identifier) as any) : undefined

    $value.set((prev: any) => {
      const newValue = {
        ...prev,
        [identifier]: toggled === '' || toggled === undefined ? true : toggled,
      }

      $isToggled.set(newValue[identifier] === undefined || newValue[identifier])

      return newValue
    })

    return () => {
      $value.set((prev: any) => {
        for (const key in prev) {
          delete prev[key]
        }

        return prev
      })
    }
  }, [])

  return (
    <>
      <Box
        baseline="none"
        absolute
        className={classes(
          p8,
          pt4,
          pb4,
          style({ right: right, top: top, zIndex: 2 }),
          isToggled ? flexi : hidei,
          props.className,
        )}
        alignCenterY
      >
        <Button
          square
          baseline="secondary"
          icon={
            <span className={'material-icons'} style={{ color: 'white', fontSize: 14 }}>
              close
            </span>
          }
          size="xs"
          label={message}
          className={z2}
          onClick={() => {
            if (value[identifier] === false) {
              $value.set((prev: any) => ({ ...prev, [identifier]: true }))
              $isToggled.set(true)
              Cookies.set(identifier, 'true')
            } else {
              $value.set((prev: any) => ({ ...prev, [identifier]: false }))
              $isToggled.set(false)
              Cookies.set(identifier, 'false')
            }
          }}
        />
      </Box>
    </>
  )
}

export const HelpFlagProvider = (props: { children: any }) => {
  const [value, $value] = useValue({})
  const location = useLocation()

  useEffect(() => void 0, [location])

  const toggleAll = () => {
    if (Object.keys(value).some((key: any) => value[key])) {
      $value.set((prev: any) => {
        for (const key in prev) {
          prev[key] = false
          Cookies.set(key, 'false')
        }

        return { ...prev }
      })
    } else {
      $value.set((prev: any) => {
        for (const key in prev) {
          prev[key] = true
          Cookies.set(key, 'true')
        }

        return { ...prev }
      })
    }
  }

  return (
    <HelpContext.Provider
      value={{
        value,
        $value,
        toggleAll,
      }}
    >
      {props.children}
    </HelpContext.Provider>
  )
}
