import { useEffect } from 'react'
import useValue from '../../hooks/use-value'

export const Loader = (props: {
  loaded?: boolean
  skeleton?: any
  component?: any
  timeout?: number
}) => {
  const { loaded, skeleton, component, timeout = 0 } = props
  const [loadedInternal, $loadedInternal] = useValue(loaded)

  useEffect(() => {
    if (loaded) {
      if (timeout) {
        setTimeout(() => {
          $loadedInternal.set(true)
        }, timeout)
      } else {
        $loadedInternal.set(true)
      }
    }
  }, [loaded])

  return <>{loadedInternal ? component : skeleton}</>
}
