import { important } from 'csx'
import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
import { ComponentSizeType } from '../__core/component.types'

/* Types ======================================================================================== */
type ClassNames = {
  component: string
  /**
   * Base class for field
   */
  base: string
  /**
   * Base class for field content section
   */
  fieldBase: string
  /**
   * Proped class for field content section
   */
  fieldProped: {
    focused: string
  }

  fieldSized: {
    xs: string
    small?: string
    sm: string
    md: string
    lg: string
    xl: string
  }

  startBase: string
  endBase: string
}

type ClassProps = {
  size?: ComponentSizeType
  square?: boolean
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { square = true } = props
  const component = style({
    width: '100%',
  })

  const base = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      '& input': {
        // border: `1px solid ${theme.boxes.border.medium}`,
        // background: theme.getBaseline('background').color.light,
        // borderRadius: 4,
      },
    },
  })

  const fieldSized = {
    xs: style({ minHeight: 20, borderRadius: important(`${!square ? 20 / 2 : 4}px`) }),
    sm: style({ minHeight: 28, borderRadius: important(`${!square ? 28 / 2 : 4}px`) }),
    md: style({ minHeight: 32, borderRadius: important(`${!square ? 32 / 2 : 4}px`) }),
    lg: style({ minHeight: 36, borderRadius: important(`${!square ? 36 / 2 : 4}px`) }),
    xl: style({ minHeight: 42, borderRadius: important(`${!square ? 42 / 2 : 4}px`) }),
  }

  const fieldBase = style({
    background: theme.getBaseline('surface').color.medium,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    $nest: {
      '&::after': {
        display: 'none',
      },
    },
  })

  const fieldProped = {
    focused: style({
      background: theme.getBaseline('surface').color.medium,
      boxShadow: `1px 1px 10px 5px ${theme.boxes.shadow.light}`,
      border: 'none',
      $nest: {
        '&::after': {
          display: 'inline-block',
        },
      },
    }),
  }

  const startBase = style({
    background: theme.getBaseline('background').color.light,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
  })

  const endBase = style({
    background: theme.getBaseline('background').color.light,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 8,
  })

  return {
    component,
    base,
    fieldBase,
    fieldProped,
    fieldSized,
    startBase,
    endBase,
  }
}

/* Export ======================================================================================= */
export const fieldClass = new StyleClass<ClassNames, ClassProps>(getNames)
