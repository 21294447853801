import { ReactNode, createContext } from 'react'
import useValue, { UseValueCallbacks } from '../hooks/use-value'

export const CacheContext = createContext<{ value?: any; $value?: UseValueCallbacks<any> }>({})

export const CacheContextProvider = ({ children }: { children: ReactNode }) => {
  const [value, $value] = useValue({ test: 'test' })

  return <CacheContext.Provider value={{ value, $value }}>{children}</CacheContext.Provider>
}
