import { useRef, ReactElement } from 'react'

import * as React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { stylesheet } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { Float } from '../float'
import { FloatNew } from '../float-new'

/* <Dropdown /> ================================================================================= */
export const Dropdown: React.FC<{
  dataKey?: string
  button?: any
  children: [ReactElement, any]
  floatProps?: any
  floatDialogProps?: any
  className?: string
  noWrap?: boolean
  fullWidth?: boolean
  onClose?: any
}> = ({ className, children, onClose, fullWidth = false, floatProps }) => {
  const [, $focus] = useBoolean(false)
  const parentRef = useRef(null)
  const [open, $open] = useBoolean(false)

  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!open) {
      $focus.toggle()
      $open.toggle()
    }
  }

  const handleClose = () => {
    $focus.set(false)
    $open.set(false)
    if (onClose) onClose()
  }

  return (
    <span className={className}>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <FloatNew open={open} content={children[1](handleClose)} {...floatProps}>
          <div className={STYLES.containerButton} onClick={handleClick} ref={parentRef}>
            {React.cloneElement(children[0])}
          </div>
        </FloatNew>
      </OutsideClickHandler>
    </span>
  )
}

const STYLES = stylesheet({
  containerButton: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})
