import { createContext } from 'react'

export const GlobalContext: any = createContext<{ cookies: object }>({
  cookies: {},
})

export const GlobalContextProvider = (props: any) => {
  const { children, cookies = {} } = props

  return <GlobalContext.Provider value={{ cookies }}>{children}</GlobalContext.Provider>
}
