import { useEffect, FC } from 'react'

import * as React from 'react'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'
import { FormActions } from '../form'

/* Styles ======================================================================================= */
import { hide } from '../../styles/styleset/hide/hide'
import { Text } from '../text/text.component'

/* <StaticFieldComponent /> ===================================================================== */
/**  See bottom for wrapped component */
const StaticFieldComponent: React.FC<FieldContextProps & { onUnmount?: any }> = props => {
  const { value = '', dispatch, name, beforeSubmit, onChange, onUnmount, validate } = props
  useEffect(() => {
    if (name && dispatch) {
      dispatch({ type: FormActions.FIELD, param: { name, value: { beforeSubmit, validate } } })
    }
  }, [])

  useEffect(() => {
    if (name && dispatch) {
      dispatch({
        type: FormActions.SET,
        param: { name, value, id: `StaticFieldComponent / ${name}` },
      })
    }

    if (onChange) {
      onChange(value)
    }

    return () => {
      if (onUnmount) {
        onUnmount(value)
      }
    }
  }, [name, value])

  return (
    <>
      <input className={hide} value={value || ''} readOnly />
      {props.formValue?.error && (
        <Text size="sm" baseline="error">
          {props.formValue?.error}
        </Text>
      )}
    </>
  )
}

export const StaticField: FC<FieldProps> = withField(StaticFieldComponent)
