import { ReactNode } from 'react'
import {
  useFloating,
  offset,
  shift,
  autoUpdate,
  size,
  useRole,
  useInteractions,
  useDismiss,
  autoPlacement,
} from '@floating-ui/react'

/* Components =================================================================================== */
import { Box } from '../box/box.component'

export const FloatNew = ({
  children,
  content,
  open,
  fullWidth = true,
  flip,
}: {
  children: ReactNode
  content: ReactNode
  open: any
  fullWidth?: boolean
  flip?: boolean
}) => {
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    middleware: [
      offset(8),
      shift(),
      fullWidth &&
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            })
          },
        }),

      autoPlacement({
        allowedPlacements: [flip ? 'bottom-end' : 'bottom-start'],
        autoAlignment: false,
      }),
    ],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  })

  const role = useRole(context, { role: 'dialog' })
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([role, dismiss])

  return (
    <>
      <span ref={reference} {...getReferenceProps()}>
        {children}
      </span>
      {open && (
        <Box
          baseline="none"
          ref={floating}
          flex={flip}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: '100%',
            zIndex: 5,
            justifyContent: flip ? 'flex-end' : 'flex-start',
          }}
          {...getFloatingProps()}
        >
          {content}
        </Box>
      )}
    </>
  )
}
