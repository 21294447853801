
import { useState } from 'react'

export type UseValueCallbacks<X> = {
  /** Updates current value */
  readonly set: (x: any) => X
  /** Resets current value to `initialValue` */
  readonly reset: () => void
  readonly get: () => X
}

export type UseValue<TYPE, RETURN_CALLBACK, OPTIONS = object> = (
  initialValue?: TYPE,
  options?: OPTIONS,
) => [TYPE, UseValueCallbacks<TYPE> & RETURN_CALLBACK]

/**
 * useValue
 *
 * Returns a stateful value, and functions to interact with it
 *
 * @param initialValue Initial value
 */
const useValue: UseValue<any, UseValueCallbacks<any>> = initialValue => {
  const [value, set] = useState(initialValue)
  const reset = () => {
    set(initialValue)
  }

  return [
    value,
    {
      set,
      reset,
      get: function get() {
        return value
      },
    },
  ]
}

export default useValue
