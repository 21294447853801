/* Components =================================================================================== */
import { AppLayoutClass } from '../../__aeki__/components/app-layout'
import { HEADER } from '../../__aeki__/components/app-layout/app-layout.constants'
import { Header } from '../../components/header'

/* App Layout Init ============================================================================== */
export const MAX_WIDTH = 1244
const AppLayout = new AppLayoutClass({ id: 'childat-public' })

AppLayout.setConfig({
  menubar: {
    active: false,
  },
  navbar: {
    active: false,
  },
  toolbar: {
    active: false,
  },
  header: {
    active: true,
  },
  // topbar: {
  //   active: true,
  // },
})

AppLayout.set(HEADER, Header)

export default AppLayout
